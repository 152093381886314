import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IOrgNotificationSettings } from '../interfaces/datastructures';
import { environment } from '../../../environments/environment.dev';

export interface IAlertMsg {
  messageTitle: string | null,
  message: string | null,
  errorStatus: string | null
};

export interface IConfirmMsg {
  modalType?: TModalType,
  messageTitle: string | null,
  message: string | null,
  confirmFunc: any
};

export type TConfirmColor = 'danger' | 'warning' | "info" | "success";
export type TModalType = 'confirm' | 'info';
export type TNotifyVia = 'slack' | 'email';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient){}

  private apiSaveOrgNotifications(settings: IOrgNotificationSettings, notifyVia: TNotifyVia ): Observable<any> {
    return this.http.post(environment.firebase.functionsUrl+'/v1/setOrgNotifications', { settings: settings, notifyVia: notifyVia}/*, {headers: {'x-api-key': 'kk'}}*/);
  }
  private apiSaveAuthUserNotifications(settings: IOrgNotificationSettings, notifyVia: TNotifyVia ): Observable<any> {
    return this.http.post(environment.firebase.functionsUrl+'/v1/setAuthUserNotifications', { settings: settings, notifyVia: notifyVia});
  }
  

  private apiRunAutoDetection(): Observable<any> {
    console.log('SCHEDULE PATTERN AUTO DETECT');
    return this.http.get(environment.firebase.functionsUrl+'/v1/runAutoDetection');
  }
  private apiRunScheduledDetection(): Observable<any> {
    console.log('SCHEDULE PATTERN ON SCHEDULE');
    return this.http.get(environment.firebase.functionsUrl+'/v1/runScheduledDetection');
  }
  


  public saveOrgNotifications(settings: IOrgNotificationSettings, notifyVia: TNotifyVia ): Promise<any> {
      return new Promise((resolve, reject) => {
      this.apiSaveOrgNotifications(settings, notifyVia)
        .subscribe(
          (data: boolean) => {
            //console.log('single owner top 3 data:',data);
            resolve(data);
            //this._checkIntegration.next(data);
            this.showAlert({
              messageTitle: `Update of Notification Settings`,
              message: `Success! Notifications settings for your organization has been successfully updated.`,
              errorStatus: 'success'
            }, 'success');
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            //this._checkIntegration.next(false);
            this.showAlert({
              messageTitle: `Update of Notification Settings`,
              message: `Something went wrong. ${error.statusText}`,
              errorStatus: `${error.status}`
            }, 'danger')
          }
        );
      });
    }

    
    public saveUserNotifications(settings: IOrgNotificationSettings, notifyVia: TNotifyVia ): Promise<any> {
      return new Promise((resolve, reject) => {
      this.apiSaveAuthUserNotifications(settings, notifyVia)
        .subscribe(
          (data: boolean) => {
            //console.log('single owner top 3 data:',data);
            resolve(data);
            //this._checkIntegration.next(data);
            this.showAlert({
              messageTitle: `Update of User Notification Settings`,
              message: `Success! Your Notifications settings has been successfully updated.`,
              errorStatus: 'success'
            }, 'success');
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            //this._checkIntegration.next(false);
            this.showAlert({
              messageTitle: `Update of User Notification Settings`,
              message: `Something went wrong. ${error.statusText}`,
              errorStatus: `${error.status}`
            }, 'danger')
          }
        );
      });
    }

    public runDetection(): Promise<any> {
      return new Promise((resolve, reject) => {
      this.apiRunAutoDetection()
        .subscribe(
          (data: boolean) => {
            //console.log('single owner top 3 data:',data);
            resolve(data);
            //this._checkIntegration.next(data);
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            //this._checkIntegration.next(false);
          }
        );
      });
    }
    public runSchedule(): Promise<any> {
      return new Promise((resolve, reject) => {
      this.apiRunScheduledDetection()
        .subscribe(
          (data: boolean) => {
            //console.log('single owner top 3 data:',data);
            resolve(data);
            //this._checkIntegration.next(data);
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            //this._checkIntegration.next(false);
          }
        );
      });
    }

  // In-app alerts and notifications:
  private _alertSubject = new Subject<{message: IAlertMsg | null, type: TConfirmColor}>();
  public alertState = this._alertSubject.asObservable();

  private _confirmModalSubject = new Subject<{message: IConfirmMsg | null, type: TConfirmColor}>();
  public confirmModal = this._confirmModalSubject.asObservable();

  showAlert(message: IAlertMsg | null, type: TConfirmColor = 'danger') {
    this._alertSubject.next({ message, type });
  }

  showConfirmModal(message: IConfirmMsg | null, type: TConfirmColor = 'info') {
    this._confirmModalSubject.next({ message, type });
  }
}
