/*
You can use subject _urlHashElementHiglight.next to pass status
of found or not found element dependent on content loading in view, 
if not then timeout will apply

You can also use subject _urlHashElementHiglight to pass status of element search to your component
, e.g. manage higlight or some popup modal showup
*/

import { Injectable } from '@angular/core';
import { NotificationsService } from './notifications.service';
import { elementAt, Subject } from 'rxjs';
import { ICardInsightsItem } from '../interfaces/dealdata';
import { ICardInsights1on1Item } from '../interfaces/ownerdata';
import { ICardInsights1on1PatternItem } from '../interfaces/m1on1data';
import { IMultiselectPills, TInsightCategory } from '../interfaces/datastructures';
import { NavigationService } from './navigation.service';
export interface ISharedElement {
  isHigligthed: boolean,
  elementId: string,
  isFound: 'loading' | 'found' | 'notfound'
}

export interface IModalChooeShareChannel {
  element: ICardInsightsItem | ICardInsights1on1Item | ICardInsights1on1PatternItem | null,
  elementType: TInsightCategory | null,
  show: boolean,
  selectUser: boolean,
  selectChannel: boolean
}

@Injectable({
  providedIn: 'root'
})

export class ShareContentService {
    public _urlHashElementHiglight = new Subject<ISharedElement>();
    public _showChooseChannelModal = new Subject<IModalChooeShareChannel>();
    public _returnChosenChannel = new Subject<IMultiselectPills>(); //it will go into the same property as selected pills does

  constructor(
    private notify: NotificationsService,
    private nav: NavigationService
  ) { }

  public listenToHashInUrl = () => {
    setTimeout(() => {
      console.log('SHARE | Listen to hash serv');

      const handleHash = async () => {
        const currentUrl = window.location.href;
        const normalizedUrl = currentUrl;//.replace(/%23/g, '#');
        const afterHash =  this.encodedFragment();//this.nav.currNavFragment ? '#'+this.nav.currNavFragment :  //this.getElementLastHash(normalizedUrl); //'#element--2723107383877397000';//
        console.log('SHARE | Listen to hash: ', afterHash, this.nav.currNavFragment);
        if (afterHash) {
          const element = await this.waitForElement(afterHash, 30000);
            console.log('SHARE | Listen to hash - target: ', element);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            } else {
              console.error('SHARE | Element not found for hash:', afterHash);
            }
        }
      };
  
      if (document.readyState === 'loading') {
        // DOM is still loading, wait for it to finish
        document.addEventListener('DOMContentLoaded', handleHash);
      } else {
        // DOM is already loaded, handle the hash immediately
        handleHash();
      }
    },0);
  }

  public copyLink = (elementId: string) => {
    console.log('SHARE | Link to element: ', elementId);
    const link = this.generateLink(elementId);

    navigator.clipboard.writeText(link)
        .then(() => {
            //alert('Link copied to clipboard!');
            this.notify.showAlert(
              {
                messageTitle: 'Link copied to clipboard!',
                message: link,
                errorStatus: 'ok'
              },
              'success'
            )
        })
        .catch((err) => {
            //console.error('Failed to copy link:', err);
            this.notify.showAlert(
              {
                messageTitle: 'Failed to copy link',
                message: 'Failed to copy link: ' + err,
                errorStatus: 'ok'
              },
              'danger'
            )
            
        });

  }

  public generateLink = (elementId: string) => {
    const currentUrl = window.location.href;
    const trimmedUrl = this.getUrlWithoutLastHash(currentUrl);
    return `${trimmedUrl}#${elementId}`;
  };

  private getUrlWithoutLastHash(url:string) {
    const normalizedUrl = url;//.replace(/%23/g, '#');
    const lastHashIndex = normalizedUrl.lastIndexOf('#');
    if (url[lastHashIndex+1] == '/') {
      return normalizedUrl;
    } else {
      return lastHashIndex !== -1 ? url.substring(0, lastHashIndex) : normalizedUrl;
    }
    
  }

  private getElementLastHash(url:string) {
    const normalizedUrl = url;//.replace(/%23/g, '#');
    const lastHashIndex = normalizedUrl.lastIndexOf('#');
    if (url[lastHashIndex+1] == '/') {
      //skip main path hash
      return null;
    } else 
    {
      return lastHashIndex !== -1 ? url.substring(lastHashIndex, normalizedUrl.length) : null;
    }
  }

private waitForElement = (selector: string, timeout: number): Promise<Element | null> => {
  return new Promise((resolve, reject) => {
    let element: HTMLHtmlElement | null = null;
    let timeoutReached = false;

    const timeoutId = setTimeout(() => {
      timeoutReached = true;
      reject(new Error(`SHARE | Timeout: Element ${selector} not found or status not updated within ${timeout}ms`));
    }, timeout);

    const check = () => {
      element = document.querySelector(selector);
      //console.log('SHARE selector: ', selector, element, document);
      if (element) {
        clearTimeout(timeoutId);
        this._urlHashElementHiglight.next({
          isHigligthed: true,
          elementId: selector,
          isFound: 'found',
        });
        resolve(element);
      } else {
        //keep checking using requestAnimationFrame if not timeouted
        if (!timeoutReached) {
          requestAnimationFrame(check);
        } else {
          console.log(`SHARE | Element search timeouted: ${selector}`);
          this._urlHashElementHiglight.next({
            isHigligthed: true,
            elementId: selector,
            isFound: 'notfound',
          });
        }
      }
    };
    check();

    const subscription = this._urlHashElementHiglight.subscribe((status) => {
      if (status.elementId === selector) {
        console.log('SHARE | Subscribe:', status);
        if (status.isFound === 'found') {
          console.log(`SHARE | Element found via subject, unsubscribe: ${selector}`);
          clearTimeout(timeoutId);
          resolve(document.querySelector(selector));
          subscription.unsubscribe();
        }
        else if (status.isFound === 'notfound') {
          console.log(`SHARE | Element not found via subject or timeouted: ${selector}`);
          clearTimeout(timeoutId);
          reject(new Error(`SHARE | Element not found: ${selector}`));
          subscription.unsubscribe();
          /*this.notify.showConfirmModal({
            modalType: 'info',
            message: `Sorry, the linked element you are looking for could not be found or element search reached timeout. Linked element may have been moved, modified, or removed from the view.`,
            messageTitle: 'Ups! Something went wrong...',
            confirmFunc: () => null
          }, 'info')*/
        }
      }
    });

    // Emit an initial "loading" status
    this._urlHashElementHiglight.next({
      isHigligthed: true,
      elementId: selector,
      isFound: 'loading',
    });
  });
};

private encodedFragment():string|undefined {
  const encodedFragment = window.location.href?.split('element')[1]?.split('#')[0];
  console.log('SHARE | encoded frgment', encodedFragment);

  if(encodedFragment) {
    return '#element'+encodedFragment;
  } else 
   return undefined;
}

}
