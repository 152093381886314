import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { IInsightState } from '../interfaces/dealdata';
import { initializeApp } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, skip } from 'rxjs';
export interface INavParams {
  org: string,
  owner: string,
  state1on1?: IInsightState,
  stateIns?: IInsightState,
  [key:string] : any
  //fragment?: string,
};

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public currNavParams!: INavParams;
  public currNavFragment!: string | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth
  ) {
    
    this.route.fragment.subscribe((fragment) => {
      console.log('PARAMS | FRAGMENT | Current fragment: ', fragment, this.encodedFragment());
      this.currNavFragment = fragment || this.encodedFragment();
    });
    
    
    this.route.queryParams
    .pipe(
      map((params) => {
        const cleanedParams: any = {};
        
        Object.keys(params).forEach((key) => {
          // Remove parts after `%` or `#` for each parameter value
          if(key != 'redirectUrl') cleanedParams[key] = params[key].split('#')[0];//.split(/[#]/)[0];
        });
        console.log('PARAMS | Cleaned: ', cleanedParams);
        return cleanedParams;
      })
    )
    .subscribe((params: any) => {
      
      const isNotEmptyObject = (obj: object): boolean => {
        return Object.keys(obj).length > 0;
      }
      
      if(isNotEmptyObject(params)) {
        this.currNavParams = {
          ...params
        };
        console.log('PARAMS SET: ', params, this.currNavFragment);
      }
      
      console.log('PARAMS | Serv listening to params: ', params, this.currNavParams);

      if (!params['owner'] && !this.currNavParams?.owner) {
        console.log('PARAMS | No owner');
        return this.afAuth.authState.subscribe((user) => {
          console.log('PARAMS | Get owner:', user);
          if(!this.currNavParams.owner) {
            this.currNavParams = {
              ...this.currNavParams,
              owner: user?.email || ''
            }
          }

          if(!this.currNavParams?.org || this.currNavParams?.org=='') {
            console.log('PARAMS | No org');
            //this.currNavParams.org = 'noorg'//JSON.parse(localStorage.getItem('orgCodeCurr')!);
          }
          
          if (this.currNavParams.owner !='' && this.currNavParams.org !=''){
            //this.setNavParams(this.currNavParams, this.currNavFragment);
          }
          
          this.setNavParams(this.currNavParams, this.currNavFragment);
          
          //this.currNavFragment = undefined;
        });
      } 
      
      
      
      
      return null;//his.setNavParams(this.currNavParams);
      
    });
    
  }

  public setNavParams(navParams: INavParams, fragment?: string) {
    console.log('PARAMS | Set new nav params: ', navParams, this.currNavFragment);
    this.currNavParams = { ...navParams };
    //!fragment ? fragment = this.currNavFragment : fragment;
    this.newNavParams(navParams, fragment); // Pass the fragment here
    //this.currNavFragment = undefined;
  }

  private newNavParams(viewParams: any, fragment?: string) {
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 
        ...viewParams
        // stateParam1on1: this.viewParams.state1on1
      },
      queryParamsHandling: 'merge', 
      fragment: fragment ||  this.currNavFragment || undefined
    });
  }

  private encodedFragment2():string|undefined {
    const encodedFragment = window.location.href.replace('#', '?')
    const resultFragment = encodedFragment.replace(/.*?(%23|#|%2523)/, ""); // Remove all before %23 or the second #
    if (resultFragment !== encodedFragment) {
      console.log('SHARE | encoded frgment', resultFragment);
      return resultFragment
    } else return undefined;
  }

  private encodedFragment():string|undefined {
    const encodedFragment = window.location.href?.split('element')[1]?.split('#')[0];
    console.log('SHARE | encoded frgment', encodedFragment);

    if(encodedFragment) {
      return 'element'+encodedFragment;
    } else 
     return undefined;
  }
  
}
