import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { OrgdataService } from 'src/app/shared/services/orgdata.service';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { Subject, Subscription, distinctUntilChanged, filter, skip, takeUntil } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';
import { environment } from 'src/environments/environment.dev';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { User } from 'src/app/shared/services/user';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit, OnDestroy {

  @Input() sidebarId: string = "sidebar";
  @Input() isProdEnv: boolean = true;

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)
  public headerAvatar = './assets/img/avatars/anonym4.jpg';
  public showPipelineFilter: boolean = false;
  public orgPipelines: string[] = [];
  public initialSelection: string[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private authSubscription!: Subscription;
  public currentOrgSelected!: string;
  private currUser: User | any;

  constructor(private classToggler: ClassToggleService,
    public authService: AuthService,
    public orgData: OrgdataService,
    private orgSet: OrgsettingService,
    private userSet: UserSettingsService,
    private router: Router,
    private ana: AnalyticsService
  ) {
    super();
    this.refreshUserData();
  }

  ngOnInit(): void {
    //console.log('header on init');
    this.router.events
      //.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((event): event is NavigationEnd | Scroll => event instanceof NavigationEnd ||  event instanceof Scroll))
      .subscribe((event: NavigationEnd | any) => {
        // Extract current route
        let currentRoute = null;
        if (event instanceof NavigationEnd){
          currentRoute = event.url;
        }
        if (event instanceof Scroll){
          //if ( event.routerEvent instanceof NavigationEnd)
          currentRoute = event.routerEvent.url;
        }

        if (currentRoute){
          //console.log('Current Route:', currentRoute, event);
          (currentRoute.includes('do-dashboard') || currentRoute.includes('ae-dashboard') || currentRoute == '/'
            ? this.showPipelineFilter = true 
            : this.showPipelineFilter = false);
        }
      });

      this.orgSet.$orgChange
      .pipe(
        takeUntil(this.destroy$),
        //withLatestFrom(this.oDat._currUserOrg),
        distinctUntilChanged(),
        //skip(1)
      )
      .subscribe(orgCode => {
        //console.log('HEADER: Listen to org changes in org data service', orgCode);
        this.orgSet.getOrgAppSettings(orgCode);
        this.orgSet._orgAppSettings.subscribe(orgset => {
          console.log('Pipelines org settings:', orgCode, orgset);
          if (orgset?.showPipelines) {
            this.orgData.getOrgFieldDistinctData('pipeline', orgset.showPipelines);
            this.orgData._globalFilterPipelines.next(orgset.showPipelines); // take initial filter from new org
          } else {
            this.orgData.getOrgFieldDistinctData('pipeline');
            this.orgData._globalFilterPipelines.next([]); 
          }
          
        });
        this.refreshUserData();
        this.currentOrgSelected = JSON.parse(localStorage.getItem('orgCodeCurr')!);
        //this.ana.setTrackingCurrOrg(this.currentOrgSelected);

      });
      
      this.orgData._dataOrgFieldDistinctSubject
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        //skip(1)
      )
      .subscribe((pipelines: any) => {
        this.orgPipelines= pipelines.filter((pipe:any) => pipe != null).sort();
        console.log('Change org pipelines list', this.orgPipelines);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  refreshUserData(){
    this.authSubscription = this.authService.afAuth.authState.subscribe((user) => {
      if (user) {
        //this.headerAvatar = (user.photoURL) ? this.sanitizer.bypassSecurityTrustUrl(user.photoURL).toString() : './assets/img/avatars/anonym4.jpg';
          this.headerAvatar = (user.photoURL) ? user?.photoURL : './assets/img/avatars/anonym4.jpg';
          
          this.currUser = user;
          this.loadUserInitialPipelines(this.currUser,this.orgData.currOrgCode);
      }
    });
  }

  public globalFilterPipelines(filter:string[]){
    console.log('globalFilerPipelines');
    this.orgData._globalFilterPipelines.next(filter);
  }

  private loadUserInitialPipelines(user: User, currOrgId: string) {
    this.userSet.getOrgUserSettings(user, currOrgId);
    this.userSet._orgUserSettings.subscribe(userset => {
      if (userset?.selectedPipelines) {
        this.initialSelection = userset.selectedPipelines;
        this.orgData._globalFilterPipelines.next(this.initialSelection);
      }
      //console.log('Set user pills', this.initialSelection);
    })
  }
}
