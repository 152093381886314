<c-modal  #verticallyCenteredModal #staticBackdropModal backdrop="static" alignment="center"  [visible]="(this.sharedElement) ? this.sharedElement.show : false" (visibleChange)="'handleModalChange($event)'">
    <c-modal-header>
      <h5 cModalTitle>{{modalTitle}}</h5>
      <button (click)="toggleModal(this.sharedElement)" cButtonClose></button>
    </c-modal-header>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <c-modal-body>
      <div class="mb-3">
      <label for="searchChannels" class="form-label">{{this.modalSubTitle}}</label>
        <input
          class="form-control"
          id="searchChannels"
          formControlName="searchChannels"
          type="text"
          placeholder="Search in Slack workspace"
          (keydown.enter)="preventSubmit($event)"
        />
        <div id="role" class="form-select-container">
          <!--<label class="form-label">Select</label>-->
          <div class="scrollable-list form-control">
            <div *ngIf="this.loadStatus == 'failed'" 
              class="spinner-loader no-data">
              Load failed
            </div>
            <div *ngIf="(this.filterBySearch.length < 1 && this.loadStatus == 'cleared')" 
              class="spinner-loader">
              <c-spinner color="primary" size="sm"></c-spinner>
              Loading...
            </div>
            <div 
              *ngFor="let f of filterBySearch" 
              [class.selected]="(selectedOption) ? f?.id == selectedOption.id : false"
              (click)="selectOption(f)">
              <div>
                <c-avatar
                  *ngIf="f.elemType == 'member'"
                  cTextColor="primary"
                  shape="rounded-1"
                  size="sm"
                  src="{{f?.profile?.image_24 || null}}"
                  referrerpolicy="no-referrer"
                >
                </c-avatar>
                <svg *ngIf="f.elemType == 'channel'"
                  class="svg-alertx" 
                  style="color: gray;"
                  cIcon name="{{f.profile.image_24}}" size="xs">
                </svg>
                {{ f?.name }}
              </div>
            </div>
          </div>
        </div>
        <label
        style="margin-top: 5px; width: fit-content;"
        [cTooltipVisible]="false" 
        [cTooltip]="'Looks like you\'re trying to find a private channel. To proceed, add Aimars App to the channel on Slack or contact your Slack admin for help.'" 
        cTooltipPlacement="top"
        > Can't find your channel?
        <img class="svg-watermark small-icon"
        style="margin-bottom: 2px"
        src="assets/icons/info.svg">
    </label>
      </div>          
    </c-modal-body>
    <c-modal-footer>
        <button (click)="'saveCommentChange()'" cButton color="primary" type="submit" [disabled]="!(selectedOption && 'searchForm.invalid')">{{'Send via Slack' + (this.selectedOption ?' to: '+this.selectedOption.name : '')}}</button>
      <button (click)="toggleModal(this.sharedElement)" cButton color="white">Cancel</button>
    </c-modal-footer>
</form>
</c-modal>