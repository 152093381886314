import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';

//app route guard
import { AuthGuard} from './shared/guard/auth.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { RegisterGoogleComponent } from './views/pages/register-google/register-google.component';
import { VerifyEmailComponent } from './views/pages/verify-email/verify-email.component';
import { DoDashboardComponent } from './views/do-dashboard/do-dashboard.component';
import { AuthResolver }  from './shared/guard/auth-resolver';

const routes: Routes = [
  /*{
    path: '',
    redirectTo: 'do-dashboard',
    pathMatch: 'full',
  },*/
  {
    path: '',
    component: DefaultLayoutComponent,
    //resolve: { isAuthenticated: AuthResolver },
    canActivate: [AuthGuard] ,
    data: {
      title: 'Home'
    },
    children: [
      
      {
        path: '',
        //redirectTo: 'do-dashboard',
        //component: DoDashboardComponent,
        canActivate: [AuthGuard],
        //resolve: { isAuthenticated: AuthResolver },
        loadChildren: () =>
          import('./views/do-dashboard/do-dashboard.module').then((m) => m.DoDashboardModule),
          //canActivate: [AuthGuard]
      },
      {
        path: 'do-dashboard',
        //resolve: { isAuthenticated: AuthResolver },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/do-dashboard/do-dashboard.module').then((m) => m.DoDashboardModule),
          //canActivate: [AuthGuard]
      },/*
      {
        path: 'main-dashboard',
        loadChildren: () =>
          import('./views/main-dashboard/main-dashboard.module').then((m) => m.MainDashboardModule),
          canActivate: [AuthGuard] 
      },*/
      {
        path: 'quotasettings',
        loadChildren: () =>
          import('./views/quota-settings/quota-settings.module').then((m) => m.QuotaSettingsModule),
          canActivate: [AuthGuard, AdminGuard] 
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
          canActivate: [AuthGuard] 
      },
      {
        path: 'userprofile',
        loadChildren: () =>
          import('./views/userprofile/userprofile.module').then((m) => m.UserProfileModule),
          canActivate: [AuthGuard] 
      },
      {
        path: 'teamsettings',
        loadChildren: () =>
          import('./views/team-settings/team-settings.module').then((m) => m.TeamSettingsDashboardModule),
          canActivate: [AuthGuard, AdminGuard] 
      },
      {
        path: 'm1on1-team',
        loadChildren: () =>
          import('./views/m1on1-team-dashboard/m1on1-team-dashboard.module').then((m) => m.M1on1TeamDashboardModule),
          canActivate: [AuthGuard] 
      },
      {
        path: 'm1on1-overview',
        loadChildren: () =>
          import('./views/m1on1-overview-dashboard/m1on1-overview-dashboard.module').then((m) => m.M1on1OverviewDashboardModule),
          canActivate: [AuthGuard] 
      },
      {
        path: 'signals-dashboard',
        loadChildren: () =>
          import('./views/signals-dashboard/signals-dashboard.module').then((m) => m.SignalsDashboardModule),
          canActivate: [AuthGuard] 
      },
      /*/*
      {
        path: 'highlights-dashboard',
        loadChildren: () =>
          import('./views/highlights-dashboard/highlights-dashboard.module').then((m) => m.HighlightsDashboardModule),
          canActivate: [AuthGuard] 
      },*/
      {
        path: 'ae-dashboard',
        loadChildren: () =>
          import('./views/ae-dashboard/ae-dashboard.module').then((m) => m.AEDashboardModule),
          canActivate: [AuthGuard] 
      },
      {
        path: 'deal-details/:id',
        loadChildren: () =>
          import('./views/deal-details/deal-details.module').then((m) => m.DealDetailsModule),
          canActivate: [AuthGuard] 
      },/*
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule)
      },/*
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then((m) => m.ButtonsModule)
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/forms.module').then((m) => m.CoreUIFormsModule)
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/charts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then((m) => m.NotificationsModule)
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule)
      },*/
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },/*
      {
        path: 'archived',
        loadChildren: () =>
          import('./views/archived-dashboards/archived-dashboards.module').then((m) => m.ArchivedDashboardsModule)
      },*/
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Reset Password Page'
    }
  },
  {
    path: 'register-google',
    component: RegisterGoogleComponent,
    data: {
      title: 'Register with Google'
    }
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    data: {
      title: 'Verify Your Email'
    }
  },
  //{path: '**', redirectTo: 'do-dashboard'}
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}