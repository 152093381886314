import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IMultiselectPills } from 'src/app/shared/interfaces/datastructures';
import { freeSet } from '@coreui/icons';
import { OrgdataService } from 'src/app/shared/services/orgdata.service';
import { Subject, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { OrgsettingService } from 'src/app/shared/services/orgsetting.service';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';

@Component({
  selector: 'app-select-pills-dropdown',
  templateUrl: './select-pills-dropdown.component.html',
  styleUrls: ['./select-pills-dropdown.component.scss']
})
export class SelectPillsDropdownComponent implements OnInit, OnChanges {

  constructor( public orgData: OrgdataService, 
    private orgSet: OrgsettingService,
    private userSet: UserSettingsService
  ){}
  @Input() data!:string[];
  @Input() initialSelection:string[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private resetSelection$ = new Subject<IMultiselectPills[]>();//selectedMultiselects
  
  private multiselects!: IMultiselectPills []; /* = [
    {id: 1, name:'Pipeline 1'},
    {id: 2, name:'Pipeline 2'},
    {id: 3, name:'Pipeline 3'},
    {id: 4, name:'Pipeline 4'},
    {id: 5, name:'Pipeline 5'},
    {id: 6, name:'Pipeline 6'} 
  ];*/

  icons = freeSet;
  searchText: string = ''; 
  public filteredMultiselects: IMultiselectPills[] = []; //checked in dropdownlist
  public selectedMultiselects: IMultiselectPills[] = []; //shown pills
  public isSelectAllChecked: boolean = false;
  public morePipelinesTooltip!: string;
  private currOrg!: string;

  ngOnInit() {

    this.orgSet.getLastOrgChange()
    .pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    )
    .subscribe(orgChanged => {
      //this.selectedMultiselects = [];
      //this.filteredMultiselects = [];
      this.multiselects = (this.data) ? this.data.map((name:string, index:number) => ({ id: index + 1, name })) : [];
      this.filteredMultiselects = (this.multiselects) ? this.multiselects.map(item => ({ ...item, checked: false })) : [];
      //this.filteredMultiselects =this.multiselects.map(item => ({ ...item, checked: this.initialSelection.includes(item.name) }));
      this.currOrg = orgChanged; 
      console.log('PIPELINES | Org change', this.multiselects, this.filteredMultiselects, this.selectedMultiselects)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    this.multiselects = this.data.map((name:string, index:number) => ({ id: index + 1, name }));
    this.filteredMultiselects =this.multiselects.map(item => ({ ...item, checked: this.initialSelection.includes(item.name) }));
    this.selectedMultiselects = this.filteredMultiselects.filter(item => item.checked == true);
    //console.log('Pills change..', this.multiselects, this.filteredMultiselects, this.selectedMultiselects, this.initialSelection);

  }

  search(): void {
    if (this.searchText) {
        this.filteredMultiselects =this.multiselects.filter(item => item.name.toLocaleUpperCase().includes(this.searchText.toLocaleUpperCase()));
    } else {
        this.filteredMultiselects =this.multiselects;
    }
    this.filteredMultiselects = this.filteredMultiselects.filter(item => !this.selectedMultiselects.includes(item));
    this.globalFilterPipelines(this.filteredMultiselects);
  }
  
  public selectMultiselect(event: any, selectedMultiselect: IMultiselectPills): void {
    const isChecked = event.target.checked;
    selectedMultiselect.checked = isChecked; 
  
    if (isChecked) {
      if (!this.selectedMultiselects.some(m => m.id === selectedMultiselect.id)) {
        this.selectedMultiselects.push(selectedMultiselect);
      }
    } else {
      this.selectedMultiselects = this.selectedMultiselects.filter(m => m.id !== selectedMultiselect.id);
    }
    this.isSelectAllChecked = this.selectedMultiselects.length ===this.multiselects.length;
    this.globalFilterPipelines(this.selectedMultiselects);
  }

  removeMultiselect(multiselectToBeRemoved: IMultiselectPills): void {
    this.selectedMultiselects = this.selectedMultiselects.filter(m => m.id !== multiselectToBeRemoved.id);
  
 
    const indexToRemove = this.filteredMultiselects.findIndex(m => m.id === multiselectToBeRemoved.id);
    if (indexToRemove !== -1) {
      this.filteredMultiselects[indexToRemove].checked = false;
    }
    this.isSelectAllChecked = this.selectedMultiselects.length === this.filteredMultiselects.length && this.selectedMultiselects.length > 0;
    this.globalFilterPipelines(this.filteredMultiselects);
  }
  
  updateCheckedStatus(multiselectToBeUpdated: IMultiselectPills): void {
    const index = this.filteredMultiselects.findIndex(
      multiselect => multiselect.id === multiselectToBeUpdated.id
    );
    if (index !== -1) {
      this.filteredMultiselects[index].checked = false;
    }
    this.globalFilterPipelines(this.filteredMultiselects);
  }


  selectAllMultiselects(event: Event | null): void {
    const checked = (event) ? (event.target as HTMLInputElement).checked : true;
    this.isSelectAllChecked = checked;
  
    if (checked) {
  
     this.multiselects.forEach(item => {
        if (!this.selectedMultiselects.some(selectedItem => selectedItem.id === item.id)) {
          this.selectedMultiselects.push({ ...item, checked: true });
        }
      });
    } else {
    
      this.selectedMultiselects.forEach(item => item.checked = false);
      this.selectedMultiselects = [];
    }  
    this.filteredMultiselects.forEach(item => item.checked = checked);
    this.globalFilterPipelines(this.filteredMultiselects);
  }
  
  public globalFilterPipelines(selectedPipelines:IMultiselectPills[]){
    
    const filter: string[] = selectedPipelines.filter(pipeline => pipeline.checked == true).map(pipeline => pipeline.name);
    console.log('Selected pipelines: ', selectedPipelines, filter);
    if (filter.length == 0 ) {
      this.orgSet.getOrgAppSettings(this.currOrg)
        .pipe(
          takeUntil(this.destroy$),
          take(1)
        )
        .subscribe(orgset => {
          this.orgData._globalFilterPipelines.next(orgset?.showPipelines || []);
        })
    } else this.orgData._globalFilterPipelines.next(filter);
    this.morePipelinesTooltip = this.multiselects.map(pipeline => pipeline.name).slice(2,this.multiselects.length).join(',');
    console.log('SAVE PIPELINE | ', this.orgData.currOrgCode, this.orgData.currUser.uid, filter);
    this.userSet.saveOrgUserSettingsPipelines(this.orgData.currUser, this.orgData.currOrgCode, filter);
  }

}
