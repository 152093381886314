import { ErrorHandler, Injectable } from '@angular/core';
import { ICardInsights1on1Item, ICardInsightsItemForOwner, IDataTopByOwner, IOwnerDataByOwnerByPipeline, IOwnerDataByOwnerByPipelineSum, IOwnerDataByOwnerSummary, IOwnerDataIncomingItem, IOwnerLisItem, IQuotaDataByOwner } from '../interfaces/ownerdata';
import { BehaviorSubject, Observable, Subject, filter, lastValueFrom, map, of, pipe, skip, throwError, timestamp } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { IBoosterBlockerItem, ICardInsightsItem, IDataTopByDeal, IDealBucketData,IDealContact,IDealEngDetailItem, IDealEngScoreSummaryItem, IDealRank, IInsightState, TDealEngScoreDetailsType } from '../interfaces/dealdata';
import { IRespDataTopByDeal, IRespDataTopByOwner, IRespDealEngScoreSummaryItem, IRespIBoosterBlockers, IRespIDealBucketData, IRespInsights1on1ItemForDeal, IRespInsights1on1ItemForOwner, IRespInsightsItemForDeal, IRespInsightsItemForOwner, IRespOwnerDataIncomingItems, IRespInsights1on1PatternItemForOrg, IRespInsights1on1PatternStatsByCategory } from '../interfaces/dataresp';
import { ActivitydataService } from './activitydata.service';
import { ICardEngDetailsItem, TCardEngDetailsTypeLabel, TInsightCategory } from '../interfaces/datastructures';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { User } from './user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DBsourceService } from './dbsource.service';
import { environment } from 'src/environments/environment.dev';
import { AuthService } from './auth.service';
import { ICardInsights1on1PatternItem, ICardInsights1on1PatternStatsByCategory } from '../interfaces/m1on1data';

TimeAgo.addDefaultLocale(en);
export interface IGlobalFilter {
  showDataForEmail: IOwnerLisItem | null,
  filteredPipelines: string[]
}

@Injectable({
  providedIn: 'root'
})
export class OrgdataService {

  //public dealownerdata!:any;
  private timeAgo = new TimeAgo('en-US');
  
  public _globalFilterPipelines = new BehaviorSubject<string[]>([]); //empty = should be all pipelines
  //public _globalShowDataForEmail = new Subject<IOwnerLisItem|null>(null); //for logged user as default
  public _globalShowDataForEmail = new BehaviorSubject<IOwnerLisItem>({
    teamLeaderEmail: '',
    dealOwnerId: 0,
    dealOwnerEmail: '',
    dealOwnerFullName: '',
    uid: null
  }); //for logged user as default
  //public _globalShowDataForEmailModified = new BehaviorSubject<IOwnerLisItem|null>(null); //for logged user as default
  public _globalShowDataForEmailModified = new BehaviorSubject<IOwnerLisItem>({
    teamLeaderEmail: '',
    dealOwnerId: 0,
    dealOwnerEmail: '',
    dealOwnerFullName: '',
    uid: null
  }); //for logged user as default

  public _globalFilters = new BehaviorSubject<IGlobalFilter>({
    showDataForEmail: null,
    filteredPipelines: []
  }); 

  public _dataSumOwnerLevelStatsSubject = new Subject<IOwnerDataByOwnerByPipeline | []>();
  //public _dataSumOwnerLevelQuotaSubject = new Subject<IQuotaDataByOwner | []>();
  //dataOwnerLevel$ = this._dataOwnerLevelSubject.asObservable();

  public _dataDealRankSubject = new Subject<IDealRank[]>();
  public _dataSingleDealRankSubject = new Subject<IDealRank | []>();
  public _dataBulkDealsSubject = new Subject<IRespIDealBucketData | []>();
  //dataDealRank$ = this.getDataDealRankAll();//this._dataDealRankSubject.asObservable();

  public _dataDealBucketsSubject = new Subject<IRespIDealBucketData>();
  //dataDealBuckets$ = this._dataDealBucketsSubject.asObservable();
  public _dataSingleDealInsightsSubject = new Subject<IRespInsightsItemForDeal>();
  public _dataSingleDealBoostersBlockersSubject = new Subject<IRespIBoosterBlockers>();

  public _dataSingleOwnerInsightsSubject = new Subject<IRespInsightsItemForOwner>();
  public _dataSingleOwnerIncomingDealsSubject = new Subject<IRespOwnerDataIncomingItems>();

  public _dataSingleDealTopSubject = new Subject<IRespDataTopByDeal>();
  public _dataSingleOwnerTopSubject = new Subject<IRespDataTopByOwner>();

  public _dataSingleOwnerAllTop3Subject = new Subject<IDataTopByOwner[]>();

  public _dataSingleOwner1on1Subject = new Subject<IRespInsights1on1ItemForOwner>();
  public _dataSingleDeal1on1InsightsSubject = new Subject<IRespInsights1on1ItemForDeal>();
  public _dataOrg1on1Subject = new Subject<IRespInsights1on1PatternItemForOrg>();
  public _dataOrg1on1StatsByCategorySubject = new Subject<IRespInsights1on1PatternStatsByCategory>();
  public _dataBulk1on1InsightsSubject = new Subject<IRespInsights1on1ItemForDeal>();
  

  public _dataSingleDealEngagementsSubject = new Subject<IDealEngDetailItem[]>();
  public _dataSingleDealEngagementsSummarySubject = new Subject<IRespDealEngScoreSummaryItem>();

  public _dataOrgFieldDistinctSubject = new Subject<string[]>();

  public _dataOrgTeamMembersListSubject = new Subject<IOwnerLisItem[]>();
  public _dataSingleDealContactsSubject = new Subject<IDealContact[]>();
  public _dataSingleOwnerStatsSubject = new Subject<any[]>();

  public currOrgCode!: string;
  public showDataForMember!: IOwnerLisItem;
  public showDataForPipeline!: string[];
  //this.testTime =this.timeAgo.format(new Date());
  public currUser!: User | any;
  _dataSingleDeal1on1Subject: any;
  
  //public  _currUserOrg = new Subject<User | any>();

  private getDataDealRankBucketsAll(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    
    if (orgCode.includes('demo')) {
      //return this.http.get('assets/data/demo/dealbuckets.json');
      return this.storageSrc.loadJsonFile(`demo/dealbuckets.json`);
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataDealRankBucketsAll',{ownerEmail: owneremail}); //UI_DEALRANK_BUCKETS_ALL
  }

  private getDataDealRankBucketsOpenWon(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    
    if (orgCode.includes('demo')) {
      //return this.http.get('assets/data/demo/dealbuckets.json');
      return this.storageSrc.loadJsonFile(`demo/dealbuckets.json`);
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataDealRankBucketsOpenWon',{ownerEmail: owneremail}); //UI_DEALRANK_BUCKETS_ALL
  }

  private getAPIDataOwnerLevelByPipelines(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      //return this.http.get('assets/data/demo/ownerlevelpipelines.json');
      return this.storageSrc.loadJsonFile(`demo/ownerlevelpipelines.json`);
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataOwnerStatsDetails',{ownerEmail: owneremail}); //to get data from Firebase directly
  }
/*
  private getAPIDataOwnerLevelQuota(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null) return of(null);
    if (orgCode.includes('demo')) {
      return this.http.get('assets/data/demo/ownerlevelquota.json'); 
    } else return this.http.get('assets/data/demo/ownerlevelquota.json'); //to do view
  }
*/
  private getAPISingleDataDealInsights(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      //return this.http.get('assets/data/demo/dealinsights.json'); 
      return this.storageSrc.loadJsonFile(`demo/dealinsights.json`);
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataDealInsights', {dealId: dealid}); // UI_INSIGHTS_DEALS
  }

  private getAPISingleDataOwnerInsights(owneremail: string, orgCode: string, start: number|null = null, count: number|null = null): Observable<any> {
    if (orgCode == null || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/ownerlevelinsights.json`);
      //return this.http.get('assets/data/demo/ownerlevelinsights.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataOwnerInsights', {ownerEmail: owneremail, start: start, count: count}); // UI_INSIGHTS_OWNER 
    
  }

  private getAPISingleDataDealBoostersBlockers(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealboostersblockers.json`);
      //return this.http.get('assets/data/demo/dealboostersblockers.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataDealBoostersBlockers', {dealId: dealid}); //UI_DEALS_BOOSTERSBLOCKERS
    //return this.http.post('assets/data/demo/dealboostersblockers.json', {dealId: dealid});
  }

  private getAPISingleDataDealRank(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealbuckets.json`);
      //return this.http.get('assets/data/demo/dealbuckets.json');
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataDealRankSingleDeal', {dealId: dealid}); //UI_DEALRANK_BUCKETS_ALL
  }

  private getAPIDataOwnerIncomingDeals(owneremail: string,  orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/ownerincomingdeals.json`);
      //return this.http.get('assets/data/demo/ownerincomingdeals.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataOwnerIncomingDeals', {ownerEmail: owneremail}); //UI_INCOMINGDEALS
  }

  private getAPIDataOwnerAllTop3(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/ownertop3.json`);
      //return this.http.get('assets/data/demo/ownertop3.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataOwnerAllTop3', {ownerEmail: owneremail}); //UI_ICP_BYLEVELS to do
  }

  private getAPIDataDealTop(dealid: string,orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealtopmatch.json`);
      //return this.http.get('assets/data/demo/dealtopmatch.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataDealTop', {dealId: dealid}); //UI_ICP_MATCH_BYDEAL to do 
  }

  private getAPIDataOwnerTop(owneremail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/ownertopmatch.json`);
      //return this.http.get('assets/data/demo/ownertopmatch.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataOwnerTop', {ownerEmail: owneremail}); //UI_ICP_MATCH_BYOWNER
  }

  private getAPIDataOwner1on1Insights(owneremail: string, orgCode: string, start: number|null = null, count: number|null = null): Observable<ICardInsights1on1Item[] | any> { //not in MVP1
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/insights1on1.json`);
      //return this.http.get('assets/data/demo/insights1on1.json');
    } else //return of([]) ;
        return this.http.post(environment.firebase.functionsUrl+'/getDataOwner1on1Insights', {ownerEmail: owneremail, start: start, count: count});// 1on1 insights-detailed
  }

  private getAPIDataSingleDeal1on1Insights(dealid: string, orgCode: string): Observable<ICardInsights1on1Item[] | any> { //not in MVP1
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/insights1on1.json`);
      //return this.http.get('assets/data/demo/insights1on1.json');
    } else //return of([]) ;
        return this.http.post(environment.firebase.functionsUrl+'/getDataDeal1on1Insights', {dealId: dealid});// 1on1 insights-detailed
  }

  private getAPIDataEngagementsSingleDeal(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealengagementdetails.json`);
      //return this.http.get('assets/data/demo/dealengagementdetails.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataEngagementsSingleDeal', {dealId: dealid}); //UI_DEALS_ENGS_DETAILS
  }
  
  private getAPIDataEngagementsSingleDealSummary(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealengscoresummary.json`);
      //return this.http.get('assets/data/demo/dealengscoresummary.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataEngagementsSingleDealSummary', {dealId: dealid}); //UI_DEALS_ENGS_DETAILS_SUMMARY
  }

  private getAPIDataOrgFieldDistinct(field: string, orgCode: string): Observable<any> { //[key in string]: string
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) { 
      return this.storageSrc.loadJsonFile(`demo/orgpipelines.json`);
      //return this.http.get('assets/data/demo/orgpipelines.json');
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataOrgFieldDistinct', {distField: field}); //UI_DEALRANK_BUCKETS_ALL e.g. getting distinct org pipelines
  }

  private getAPIDataOrgTeamMembers(leaderemail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/teammembers_anyleader.json`);
      //return this.http.get('assets/data/demo/teammembers_anyleader.json'); //comming from Firebase
    } else return this.http.post(environment.firebase.functionsUrl+'/getDataTeamMembers', {leaderEmail: leaderemail} );
    //return this.http.get('assets/data/demo/teammembers.json'); //comming from Firebase
  }

  private getAPIDataDealContacts(dealid: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealcontacts.json`);
      //return this.http.get('assets/data/demo/dealcontacts.json'); //comming from Firebase
    } else return this.http.post(environment.firebase.functionsUrl+'/getDealContacts', {dealId: dealid}); //UI_CONTACTS_BYDEAL
  }

  private getAPIDataM1on1OrgPatterns(leaderemail: string, orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/patterninsights1on1.json`);
    } else return this.http.post(environment.firebase.functionsUrl+'/get1on1Patterns', {leaderEmail: leaderemail} );
  }

  private getAPIDataM1on1OrgPatternsStatsByCategory(leaderemail: string, orgCode: string, lastdays: number): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/patterninsightsstats1on1.json`);//of([])
    } else return this.http.post(environment.firebase.functionsUrl+'/get1on1PatternsStatsByCategory', {leaderEmail: leaderemail, lastDays: lastdays} );
  }

  private getAPIBulkDataDeals(dealids: string[], orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/dealbuckets.json`);
      //return this.http.get('assets/data/demo/dealbuckets.json');
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataBulkDeals', {dealIds: dealids}); //UI_DEALRANK_BUCKETS_ALL
  }

  private getAPIBulkData1on1InsightsByDeals(dealids: string[], orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/insights1on1.json`);
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataBulkInsightsByDeal', {dealIds: dealids}); //UI_DEALRANK_BUCKETS_ALL
  }

  private getAPIBulkData1on1InsightsByIns(insightids: string[], orgCode: string): Observable<any> {
    if (orgCode == null  || orgCode == undefined) return of(null);
    if (orgCode.includes('demo')) {
      return this.storageSrc.loadJsonFile(`demo/insights1on1.json`);
    } else 
    return this.http.post(environment.firebase.functionsUrl+'/getDataBulkInsightsByIns', {insightIds: insightids}); //UI_DEALRANK_BUCKETS_ALL
  }
  
  

  getEmails(){
    console.log('getEmails');
    this.http.get(environment.firebase.functionsUrl+'/owners').subscribe(data=>{
    console.log(data)
    
    })
  }


  constructor(
      //public orgSet: OrgsettingService,
      private http:HttpClient,
      private actData: ActivitydataService,
      private authServ: AuthService,
      private afAuth: AngularFireAuth,
      //private userSet: UserSettingsService
      private storageSrc: DBsourceService
    ) { 
      //console.log('ORG DATA SERVICE init ----');

    this._globalFilterPipelines.subscribe(pipelinesFilter => {
      //console.log('Pipelines filter: ', pipelinesFilter);
      this.showDataForPipeline = pipelinesFilter;
      
    });

    this._globalShowDataForEmail
    .subscribe((showForEmail: IOwnerLisItem | null | any) => {

      //console.log('Get data for email............', showForEmail, this.currOrgCode)

      if (!showForEmail){
        console.log('>>>>>>>>>>>>>Get data for user that exists1', showForEmail);
        //this.authServ._getCurrUser
        this.afAuth.authState
        .subscribe((currUser:any) => {
          const showDataForMember = {
            teamLeaderEmail: (this.currOrgCode.includes('demo')) ? 'email@demo' : currUser?.email,
            dealOwnerId: 0, //to fix later, this should be taken from team selection list of stored on user data settings
            dealOwnerEmail: (this.currOrgCode.includes('demo')) ? 'email@demo' : currUser?.email,
            dealOwnerFullName: currUser?.displayName,
            uid: currUser?.uid
          }
          this.showDataForMember = showDataForMember;
          this._globalShowDataForEmailModified.next(showDataForMember);
          //console.log('GLOBAL CHANGE ---------> Show data for:',  this.showDataForMember);
          //console.log('No email to show data for, go to logged user', this.showDataForMember); // to do
        })
       
      } else {
        //console.log('>>>>>>>>>>>>>Get data for user that exists2', showForEmail);
          if(this.currUser?.email == showForEmail?.dealOwnerEmail){
            let dbUser: User | null;
            this.authServ.replaceUserDetailsFromDB(this.currUser).then(user => {
              dbUser = user;
              const showDataForMember = {
                teamLeaderEmail: (this.currOrgCode.includes('demo')) ? 'email@demo' : showForEmail?.teamLeaderEmail,
                dealOwnerId: showForEmail?.dealOwnerId,
                dealOwnerEmail: (this.currOrgCode.includes('demo')) ? 'email@demo' : showForEmail?.dealOwnerEmail,
                dealOwnerFullName: dbUser?.displayName || showForEmail?.dealOwnerFullName,
                uid: this.currUser.uid
              }
              console.log('Current user is equal to show for >>>>>>>>>>>>>', showDataForMember);
              this.showDataForMember = showDataForMember;
              this._globalShowDataForEmailModified.next(showDataForMember);
              //this._globalShowDataForEmail.next(this.showDataForMember);
            })
          } else {
            this.showDataForMember = showForEmail;
            this._globalShowDataForEmailModified.next(showForEmail);
          }
          //this._globalShowDataForEmailModified.next(this.showDataForMember);
          //console.log('GLOBAL CHANGE ---------> Show data for:',  this.showDataForMember);
      }
      
    })

  }

  public getLastModifiedUser() {
    return this._globalShowDataForEmailModified.asObservable();
  }

  public getOwnerDataByPipeline(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<IOwnerDataByOwnerByPipeline> {
    this._dataSumOwnerLevelStatsSubject.next([]);//this shows undefined in progress widgets for a sec -- to fix before turning this on
    //showDataForMember = this.modifyMember(showDataForMember);
    return new Promise((resolve, reject) => {
      this.getAPIDataOwnerLevelByPipelines(showDataForMember?.dealOwnerEmail, currOrgCode)
      .pipe(map((data: IOwnerDataByOwnerByPipeline[]) => (data) ? data.filter(data => data?.dealOwnerEmail == showDataForMember?.dealOwnerEmail) : []))
      .pipe(map((data: IOwnerDataByOwnerByPipeline[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
      .pipe(map((filtered: IOwnerDataByOwnerByPipeline[]) => this.sumOwnerLevelDataByGlobalFilter(filtered)))
      .subscribe(
        (filteredData: IOwnerDataByOwnerByPipeline) => {
          console.log('Pipeline data e.g. Quota:', filteredData)
          resolve(filteredData);
          this._dataSumOwnerLevelStatsSubject.next(filteredData); 
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSumOwnerLevelStatsSubject.next([]);
        }
      );
    });
  }

private applyPipelinesFilter(pipelinesFilter: string[], dataSource: any[]):any {
    let data;
    if (pipelinesFilter.length != 0) {
      data = dataSource.filter((pipeline:any) => pipelinesFilter.includes(pipeline.pipeline))
    } else {
      data = dataSource;
    }
    //console.log('pipe  filter: ', pipelinesFilter, data)
    return data;
  }

private applyTimeAgo(data: IDealBucketData[]):IDealBucketData[]{
  data.forEach(data => {
    data.timeago_createdMillis = (typeof data.createdMillis == 'number') ? this.timeAgo.format(new Date(data.createdMillis)) : null;
    data.timeago_lastActMillis = (typeof data.lastActMillis == 'number') ? this.timeAgo.format(new Date(data.lastActMillis)) : null;
    data.timeago_lastStateChangeMillis = (typeof data.lastStateChangeMillis == 'number') ? this.timeAgo.format(new Date(data.lastStateChangeMillis)) : null;
    data.timeago_lastCloseDateChange = (typeof data.lastCloseDateChange == 'number') ? this.timeAgo.format(new Date(data.lastCloseDateChange)) : null;
  })
  //console.log('Apply time ago: ', data);
  return data;
}

public getDealBucketsData(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<IRespIDealBucketData | any> {
    this._dataDealBucketsSubject.next({data:[], status: 'cleared'});
    return new Promise((resolve, reject) => {
      this.getDataDealRankBucketsOpenWon(showDataForMember?.dealOwnerEmail, currOrgCode)
      .pipe(map((data: IDealBucketData[]) => data.filter(data => data?.dealOwnerEmail == showDataForMember?.dealOwnerEmail)))
      .pipe(
        map((data: IDealBucketData[]) => this.applyPipelinesFilter(this.showDataForPipeline, data))
      )
      .pipe(
        map((data: IDealBucketData[]) => this.applyTimeAgo(data))
      )
      .subscribe(
        async (cleanedData: IDealBucketData[]) => {

          //const cleanedData = await this.removeBlackListed(filteredData, 'dealId');
          resolve(cleanedData);
          this._dataDealBucketsSubject.next({data: cleanedData, status: 'success'});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataDealBucketsSubject.next({data: [], status: 'failed'});
        }
      );
    });
  }

  public getBulkDealsBucketsData(dealIds: string[]): Promise<IRespIDealBucketData | any> {
    this._dataBulkDealsSubject.next({data:[], status: 'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIBulkDataDeals(dealIds, this.currOrgCode)
      .pipe(
        map((items: IDealBucketData[]) => {
          // Convert all elements of insightIds to strings
          const dealIdsAsString = dealIds.map(id => id.toString());
          if (this.currOrgCode.includes('demo')) {
            return items.filter(item => dealIdsAsString.includes(item.dealId.toString()));
          } else {
            return items;
          }
        })
      )
      //.pipe(map((data: IDealBucketData[]) => data.filter(deal => dealIds.includes(String(deal.dealId))))) // to do: add filter for demo oranization
      //.pipe(map((data: IDealBucketData[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
      //.pipe(map((data: IDealBucketData[]) => this.applyTimeAgo(data)))
      .subscribe(
        async (cleanedData: IDealBucketData[]) => {

          //const cleanedData = await this.removeBlackListed(filteredData, 'dealId');
          resolve(cleanedData);
          this._dataBulkDealsSubject.next({data: cleanedData, status: 'success'});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataBulkDealsSubject.next({data: [], status: 'failed'});
        }
      );
    });
  }

  public getBulk1on1InsightsData(insightIds: string[]): Promise<IRespInsights1on1ItemForDeal | any> {
    console.log('Bulk insighs', insightIds);
    this._dataBulk1on1InsightsSubject.next({data:[], status: 'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIBulkData1on1InsightsByIns(insightIds, this.currOrgCode)
      .pipe(
        map((items: ICardInsights1on1Item[]) => {
          // Convert all elements of insightIds to strings
          const insightIdsAsString = insightIds.map(id => id.toString());
          if (this.currOrgCode.includes('demo')) {
            return items.filter(item => insightIdsAsString.includes(item.insightId.toString()));
          } else {
            return items;
          }
        })
      )
      //.pipe(map((data: IDealBucketData[]) => data.filter(deal => dealIds.includes(String(deal.dealId))))) // to do: add filter for demo oranization
      //.pipe(map((data: IDealBucketData[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
      //.pipe(map((data: IDealBucketData[]) => this.applyTimeAgo(data)))
      .subscribe(
        async (cleanedData: ICardInsights1on1Item[]) => {
          
          //const cleanedData = await this.removeBlackListed(filteredData, 'dealId');
          resolve(cleanedData);
          this._dataBulk1on1InsightsSubject.next({data: cleanedData, status: 'success'});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataBulk1on1InsightsSubject.next({data: [], status: 'failed'});
        }
      );
    });
  }
/*
  //get insights from deals that match the array
  public async getBulk1on1InsightsData2(dealIds: string[], insightIds: string[]): Promise<IRespInsights1on1ItemForDeal | any> {
    this._dataBulk1on1InsightsSubject.next({data:[], status: 'cleared'});
    try {
      // Fetch modified deal insights
      let modified1on1DealInsights:ICardInsightsItem[] = await
        this.actData.getModifiedInsightsByType(null, dealIds, this.currOrgCode, 'oneonone', 'org')
      //modified1on1DealInsights.filter((ins: ICardInsightsItem) => insightIds.includes(String(ins.insightId)));
          
      console.log('OFF | modifiedDealInsights', modified1on1DealInsights);
      if (modified1on1DealInsights && modified1on1DealInsights.length > 0) {
        let merged1on1DealInsights: ICardInsightsItem[]|any = [];
        
        await this.getAPIBulkData1on1InsightsByIns(insightIds, this.currOrgCode)
          .pipe(map((data: ICardInsightsItem[]| any) => {
            if(this.currOrgCode.includes('demo')){
              //return data.filter((data:ICardInsightsItem[]| any) => data?.dealId == dealid)
            } else return data.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
          }))
          //.pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
          .toPromise()
          .then(
            (generatedDealInsights) => {
              //console.log('generatedDealInsights',generatedDealInsights);
              merged1on1DealInsights= this.mergeInsightsData(modified1on1DealInsights, generatedDealInsights)
              .sort((a:ICardInsightsItem,b:ICardInsightsItem) => b.insightCreated - a.insightCreated )
            }
          );
        
        //console.log('mergedDealInsights',mergedDealInsights);
        this._dataBulk1on1InsightsSubject.next({data: merged1on1DealInsights, status: 'success'});
        // Resolve the promise with the retrieved data
        return merged1on1DealInsights;
      } else {
        // If there are no modified insights, just go for generated
        const generated1on1DealInsights = await this.getAPIBulkData1on1InsightsByIns(insightIds, this.currOrgCode)
        .pipe(map((data: ICardInsightsItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            //return data.filter((data:ICardInsightsItem[]| any) =>  data?.dealId == dealid)
            return  data;//.filter((ins: ICardInsightsItem) => insightIds.includes(String(ins.insightId)));
          } else return data.filter((ins: ICardInsightsItem) => insightIds.includes(String(ins.insightId)));
        }))
        .pipe(map((data: ICardInsightsItem[]|any) => data.sort((a:any,b:any) => a.insightCreated - b.insightCreated )))
        //.pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
        .toPromise()
        this._dataBulk1on1InsightsSubject.next({data: generated1on1DealInsights, status: 'success'});
        return generated1on1DealInsights;
      }
    } catch (error) {
      this._dataBulk1on1InsightsSubject.next({data:[], status: 'failed'});
      return Promise.reject(error);
    }
  }*/

  public async getDealBoostersBlockersData(dealId: string): Promise<IRespIBoosterBlockers | any> {
    this._dataSingleDealBoostersBlockersSubject.next({data:[], status:'cleared'});
    const limitToRecentBlockers = 8;
    return new Promise((resolve, reject) => {
      this.getAPISingleDataDealBoostersBlockers(dealId, this.currOrgCode)
      .pipe(map((data: IBoosterBlockerItem[]) => data.filter(data => data?.dealId == dealId)))
      .pipe(
        map((data: IBoosterBlockerItem[]) => data)
      ).subscribe(
        async (data: IBoosterBlockerItem[]) => {
          const cleanedData = await this.removeBlackListed(data, 'boostblockId');
          //console.log('bb data:',data);
          const blockers = cleanedData.filter(data => data.boostblockType == 'BLOCKER')
            .sort((a:IBoosterBlockerItem,b:IBoosterBlockerItem) => b.sourceTextCreatedMillis - a.sourceTextCreatedMillis)
            .slice(0,limitToRecentBlockers) 
          const boosters = cleanedData.filter(data => data.boostblockType == 'BOOSTER')
            .sort((a:IBoosterBlockerItem,b:IBoosterBlockerItem) => b.sourceTextCreatedMillis - a.sourceTextCreatedMillis)
            .slice(0,limitToRecentBlockers) 
          const bbdata = blockers.concat(boosters);
          resolve(bbdata);
          this._dataSingleDealBoostersBlockersSubject.next({data: bbdata, status: 'success'});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleDealBoostersBlockersSubject.next({data:[], status: 'failed'});
        }
      );
    });
  }

  public getSingleDealRankData(dealId: string): Promise<any> {
    this._dataSingleDealRankSubject.next([]);
    return new Promise((resolve, reject) => {
      this.getAPISingleDataDealRank(dealId, this.currOrgCode)
      .pipe(map((data: IDealRank[]) => data.filter(data => data?.dealId == dealId)))
      .pipe(
        map((data: IDealRank[]) => data)
      ).subscribe(
        (data: IDealRank[]) => {
          //console.log('single deal rank data:',data);
          resolve(data);
          this._dataSingleDealRankSubject.next(data[0]);
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleDealRankSubject.next([]);
        }
      );
    });
  }
  

  public getSingleOwnerIncomingDealsData(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<any> {
    this._dataSingleOwnerIncomingDealsSubject.next({data:[], status:'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIDataOwnerIncomingDeals(showDataForMember?.dealOwnerEmail, currOrgCode)
      .pipe(map((data: IOwnerDataIncomingItem[]) => (data) ? data.filter(data => data?.dealOwnerEmail == showDataForMember?.dealOwnerEmail) : []))
      .pipe(map((data: IOwnerDataIncomingItem[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
      .subscribe(
        (data: IOwnerDataIncomingItem[]) => {
          //console.log('single owner deal incoming data:',data);
          resolve(data);
          this._dataSingleOwnerIncomingDealsSubject.next({data:data, status:'success'});
        },
        (error:HttpErrorResponse) => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleOwnerIncomingDealsSubject.next({data:[], status:'failed'});
        }
      );
    });
  }

  public getSingleOwnerAllTop3Data(): Promise<any> {
    this._dataSingleOwnerAllTop3Subject.next([]);
    return new Promise((resolve, reject) => {
      this.getAPIDataOwnerAllTop3(this.showDataForMember?.dealOwnerEmail, this.currOrgCode)
      .pipe(map((data: IDataTopByOwner[]) => data.filter(data => data.dealOwnerEmail == this.showDataForMember?.dealOwnerEmail)))
      .pipe(
        map((data: IDataTopByOwner[]) => data)
      ).subscribe(
        (data: IDataTopByOwner[]) => {
          //console.log('single owner top 3 data:',data);
          resolve(data);
          this._dataSingleOwnerAllTop3Subject.next(data);
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleOwnerAllTop3Subject.next([]);
        }
      );
    });
  }

  public getSingleDealTopData(dealId: string, ): Promise<IRespDataTopByDeal | any> {
    this._dataSingleDealTopSubject.next({data:[], status:'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIDataDealTop(dealId, this.currOrgCode)
      .pipe(
        map((data: IDataTopByDeal[]) => data)
      ).subscribe(
        (data: IDataTopByDeal[]) => {
          //console.log('single deal top data:',data);
          resolve(data);
          this._dataSingleDealTopSubject.next({data: data, status: 'success'});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleDealTopSubject.next({data: [], status: 'failed'});
        }
      );
    });
  }
  public getSingleOwnerTopData(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<any> {
    //console.log('top data for:', showDataForMember?.dealOwnerEmail, currOrgCode )
    this._dataSingleOwnerTopSubject.next({data: [], status: 'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIDataOwnerTop(showDataForMember?.dealOwnerEmail, currOrgCode)
      .pipe(map((data: IDataTopByOwner[]) => (data) ? data.filter(data => data.dealOwnerEmail == showDataForMember?.dealOwnerEmail) : []))
      //.pipe(map((data: IDataTopByOwner[]) => data))
      .subscribe(
        (data: IDataTopByOwner[]) => {
          //console.log('single owner top data:',data,showDataForMember);
          resolve(data);
            this._dataSingleOwnerTopSubject.next({data: data, status: 'success'})  
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleOwnerTopSubject.next({data: [], status: 'failed'});
        }
      );
    });
  }

  public getOrgTeamMembers(): Promise<any> {
    //console.log('Get org team members....')
    this._dataOrgTeamMembersListSubject.next([]);
    return new Promise((resolve, reject) => {
      this.getAPIDataOrgTeamMembers(this.showDataForMember?.dealOwnerEmail, this.currOrgCode)
      .pipe(map((data: IOwnerLisItem[]) => {
        if(this.currOrgCode.includes('demo')){
          return data.filter(data => data?.teamLeaderEmail == 'leader@demo')
        } else return data
      }))
      .pipe(
        map((data: IOwnerLisItem[]) => data)
      ).subscribe(
        (data: IOwnerLisItem[]) => {
          console.log('team members list:',data, this.showDataForMember?.dealOwnerEmail, this.currOrgCode );
          resolve(data);
          this._dataOrgTeamMembersListSubject.next(data);
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataOrgTeamMembersListSubject.next([]);
        }
      );
    });
  }
/********* M1on1 Data **********/
/*
  public get1on1OrgPatterns2(): Promise<IRespInsights1on1PatternItemForOrg | any> {
    console.log('Get org 1 on 1 patterns....')
    this._dataOrg1on1Subject.next({status: 'cleared', data: []});
    return new Promise((resolve, reject) => {
      this.getAPIDataM1on1OrgPatterns(this.showDataForMember?.dealOwnerEmail, this.currOrgCode)
      .pipe(map((data: ICardInsights1on1PatternItem[]) => {
        if(this.currOrgCode.includes('demo')){
          return data;//.filter(data => data?.teamLeaderEmail == 'leader@demo')
        } else return data
      }))
      .pipe(
        map((data: ICardInsights1on1PatternItem[]) => data)
      ).subscribe(
        (data: ICardInsights1on1PatternItem[]) => {
          console.log('1 on 1 patterns all data:',data, this.showDataForMember?.dealOwnerEmail, this.currOrgCode );
          resolve(data);
          this._dataOrg1on1Subject.next({status: 'success', data: data});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataOrg1on1Subject.next({status: 'failed', data: []});
        }
      );
    });
  }
    */

  public async get1on1OrgPatterns(): Promise<IRespInsights1on1PatternItemForOrg | any> {
    this._dataOrg1on1Subject.next({status: 'cleared', data: []});
    try {
      // Fetch modified deal insights
      const modifiedPatternInsights:any = await this.actData.getModifiedInsightsByType(this.showDataForMember?.uid,null,this.currOrgCode, 'oneonone-pattern', 'org');
      console.log('MODIFIED PATTERNS', modifiedPatternInsights);
      if (modifiedPatternInsights && modifiedPatternInsights.length > 0) {
        let mergedDealInsights: ICardInsights1on1PatternItem[]|any = [];
        await this.getAPIDataM1on1OrgPatterns(this.showDataForMember?.dealOwnerEmail, this.currOrgCode)
        .pipe(map((data: ICardInsights1on1PatternItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            return data;//.filter((data:ICardInsights1on1PatternItem[]| any) => data?.dealId == dealid)
          } else return data;//.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
        }))
        .toPromise()
        .then(
          (generatedPatternInsights) => {
            //console.log('generatedDealInsights',generatedDealInsights);
            mergedDealInsights= this.mergeInsightsData(modifiedPatternInsights, generatedPatternInsights)
          }
        );
        //console.log('mergedDealInsights',mergedDealInsights);
        //take modified data and add not existing ones from generated
        
        // Update the subject with the retrieved data
        this._dataOrg1on1Subject.next({data: mergedDealInsights, status: 'success'});
        // Resolve the promise with the retrieved data
        return mergedDealInsights;
      } else {
        // If there are no modified insights, just go for generated
        const generatedPatternInsights = await this.getAPIDataM1on1OrgPatterns(this.showDataForMember?.dealOwnerEmail, this.currOrgCode)
        .pipe(map((data: ICardInsightsItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            return data;//.filter((data:ICardInsightsItem[]| any) =>  data?.dealId == dealid)
          } else return data;
        }))
        .toPromise()
        this._dataOrg1on1Subject.next({data: generatedPatternInsights, status: 'success'});
        return generatedPatternInsights;
      }
    } catch (error) {
      this._dataOrg1on1Subject.next({data:[], status: 'failed'});
      return Promise.reject(error);
    }
  }

    public async get1on1OrgPatternsCalcStatsByCategory(lastDays: number) {
    console.log('Get org 1 on 1 stats....')
    //this._dataOrg1on1StatsByCategorySubject.next({status: 'cleared', data: [], lastDays: lastDays});
    try {
        const gendata:any = await this.getAPIDataM1on1OrgPatternsStatsByCategory(this.showDataForMember?.dealOwnerEmail, this.currOrgCode, lastDays)
        /*
        .pipe(map((data: ICardInsights1on1PatternStatsByCategory[]) => {
          console.log('Get org 1 on 1 stats.... - DATA LOADED')
          if(this.currOrgCode.includes('demo')){
            return data; //.filter(data => data?.teamLeaderEmail == 'leader@demo')
          } else return data
        }))*/
        .toPromise()

        this._dataOrg1on1StatsByCategorySubject.next({status: 'success', data: gendata, lastDays: lastDays});
        // Resolve the promise with the retrieved data
        return gendata;
    } catch (error) {
      this._dataOrg1on1StatsByCategorySubject.next({status: 'failed', data: [], lastDays: lastDays});
      return Promise.reject(error);
    }
  }
/*
  public get1on1OrgPatternsCalcStatsByCategory2(lastDays: number): Promise<any> {
    console.log('Get org 1 on 1 stats....')
    this._dataOrg1on1StatsByCategorySubject.next({status: 'cleared', data: [], lastDays: lastDays});
    return new Promise((resolve, reject) => {
      this.getAPIDataM1on1OrgPatternsStatsByCategory(this.showDataForMember?.dealOwnerEmail, this.currOrgCode, lastDays)
      .pipe(map((data: ICardInsights1on1PatternStatsByCategory[]) => {
        console.log('Get org 1 on 1 stats.... - DATA LOADED')
        if(this.currOrgCode.includes('demo')){
          return data; //.filter(data => data?.teamLeaderEmail == 'leader@demo')
        } else return data
      }))
      .subscribe(
        (data: ICardInsights1on1PatternStatsByCategory[]) => {
          console.log('1 on 1 patterns stats data:',data, this.showDataForMember?.dealOwnerEmail, this.currOrgCode );
          resolve(data);
          this._dataOrg1on1StatsByCategorySubject.next({status: 'success', data: data, lastDays: lastDays});
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataOrg1on1StatsByCategorySubject.next({status: 'failed', data: [], lastDays: lastDays});
        }
      );
    });
  }
*/
  /******** Engagements Data ************/

  public getSingleDealEngagementsData(dealId: string): Promise<any> {
    this._dataSingleDealEngagementsSubject.next([]);
    return new Promise((resolve, reject) => {
      this.getAPIDataEngagementsSingleDeal(dealId, this.currOrgCode)
      .pipe(map((data: IDealEngDetailItem[]) => data.filter(data => data?.dealId == dealId)))
      .pipe(
        map((data: IDealEngDetailItem[]) => data)
      ).subscribe(
        async (data: IDealEngDetailItem[]) => {
          //console.log('single deal engagements details data:',data);
          const cleanedData = await this.removeBlackListed(data, 'detailId');
          resolve(cleanedData);
          this._dataSingleDealEngagementsSubject.next(cleanedData);
        },
        error => {
          reject(error); // Reject if there is an error in fetching data
          this._dataSingleDealEngagementsSubject.next([]);
        }
      );
    });
  }

  public getSingleDealEngagementsSummaryData(dealId: string): Promise<any> {
    this._dataSingleDealEngagementsSummarySubject.next({status: 'cleared', data: []});
    return new Promise((resolve, reject) => {
      this.getAPIDataEngagementsSingleDealSummary(dealId, this.currOrgCode)
      .pipe(map((data: IDealEngScoreSummaryItem[]) => data.filter(data => data?.dealId == dealId)))
      .pipe(
        map((data: IDealEngScoreSummaryItem[]) => data)
      ).subscribe(
        (data: IDealEngScoreSummaryItem[]) => {
          //console.log('single deal engagements summary details data:',data);
          resolve(data);
          this._dataSingleDealEngagementsSummarySubject.next({status: 'success', data: data});
        },
        error => {
          this._dataSingleDealEngagementsSummarySubject.next({status: 'failed', data: []});
          reject(error); // Reject if there is an error in fetching data
          
        }
      );
    });
  }

  public getSingleDealContactsData(dealId: string): Promise<any> {
    this._dataSingleDealContactsSubject.next([]);
    return new Promise((resolve, reject) => {
      this.getAPIDataDealContacts(dealId, this.currOrgCode)
      .pipe(map((data: IDealContact[]) => data.filter(data => data?.dealId == dealId)))
      .pipe(
        map((data: IDealContact[]) => data)
      ).subscribe(
        (data: IDealContact[]) => {
          //console.log('single deal engagements summary details data:',data);
          resolve(data);
          this._dataSingleDealContactsSubject.next(data);
        },
        error => {
          this._dataSingleDealContactsSubject.next([]);
          reject(error); // Reject if there is an error in fetching data
        }
      );
    });
  }

  /************* Insifghts ****************/

  public async getSingleDealInsightsData(dealid: string): Promise<IRespInsightsItemForDeal | any> {
    this._dataSingleDealInsightsSubject.next({data:[], status: 'cleared'});
    const limitGenDealInsights = 15;
    try {
      // Fetch modified deal insights
      const modifiedDealInsights:any = await this.actData.getModifiedInsightsByType(null,dealid,this.currOrgCode, 'deal', 'deal'); //this.actData.getModifiedOrgDealInsights(dealid);
      //console.log('modifiedDealInsights', modifiedDealInsights);
      if (modifiedDealInsights && modifiedDealInsights.length > 0) {
        let mergedDealInsights: ICardInsightsItem[]|any = [];
        await this.getAPISingleDataDealInsights(dealid, this.currOrgCode)
        .pipe(map((data: ICardInsightsItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            return data.filter((data:ICardInsightsItem[]| any) => data?.dealId == dealid)
          } else return data.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
        }))
        .pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
        .toPromise()
        .then(
          (generatedDealInsights) => {
            //console.log('generatedDealInsights',generatedDealInsights);
            mergedDealInsights= this.mergeInsightsData(modifiedDealInsights, generatedDealInsights)
            .sort((a:ICardInsightsItem | any,b:ICardInsightsItem | any) => b.sourceTextCreatedMillis - a.sourceTextCreatedMillis )
          }
        );
        //console.log('mergedDealInsights',mergedDealInsights);
        //take modified data and add not existing ones from generated
        
        // Update the subject with the retrieved data
        this._dataSingleDealInsightsSubject.next({data: mergedDealInsights, status: 'success'});
        // Resolve the promise with the retrieved data
        return mergedDealInsights;
      } else {
        // If there are no modified insights, just go for generated
        const generatedDealInsights = await this.getAPISingleDataDealInsights(dealid, this.currOrgCode)
        .pipe(map((data: ICardInsightsItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            return data.filter((data:ICardInsightsItem[]| any) =>  data?.dealId == dealid)
          } else return data.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
        }))
        .pipe(map((data: ICardInsightsItem[]|any) => data.sort((a:any,b:any) => b.sourceTextCreatedMillis - a.sourceTextCreatedMillis )))
        .pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
        .toPromise()
        this._dataSingleDealInsightsSubject.next({data: generatedDealInsights, status: 'success'});
        return generatedDealInsights;
      }
    } catch (error) {
      this._dataSingleDealInsightsSubject.next({data:[], status: 'failed'});
      return Promise.reject(error);
    }
  }


  

  //1.Get modified insights
  //2.Get API insights - first 20
  //3.Check how many modified exists in 20 insights
  //4. Load more insights from APi from 20+how many exists

public async getSingleOwnerInsightsData(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<IRespInsightsItemForOwner | any> {
  this._dataSingleOwnerInsightsSubject.next({data: [], status: 'cleared'});
  const ownerInsigtsLimit = 8;

  try {
    // 1. Get modified insights
    const modifiedOwnerInsights: any = await  this.actData.getModifiedInsightsByType(showDataForMember?.uid, null, currOrgCode, 'deal', 'owner'); //this.actData.getModifiedOwnerInsights(showDataForMember?.uid, currOrgCode);
    const filteredModifiedInsights: ICardInsightsItemForOwner[] = this.applyPipelinesFilter(this.showDataForPipeline, modifiedOwnerInsights);
    console.log('modifiedOwnerInsights: ',modifiedOwnerInsights)
    // 2. Fetch the first 20 API insights
    const fetchAPIInsights = (start: number, count: number) => {
      const memberEmail = (currOrgCode.includes('demo')) ? 'email@demo' : showDataForMember?.dealOwnerEmail;
      return this.getAPISingleDataOwnerInsights(memberEmail, currOrgCode, start, count)
        .pipe(map((data: ICardInsightsItemForOwner[]) => data.filter(data => data.dealOwnerEmail == memberEmail)))
        .pipe(map((data: ICardInsightsItemForOwner[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
        .pipe(map((data: ICardInsightsItemForOwner[] | any) => data.filter((data:any) => data.assocDetailType !='ACT_ALL' ))) // to do: remove in BQ)
        .toPromise();
    };

    let apiInsights: ICardInsightsItemForOwner[] = await fetchAPIInsights(0, ownerInsigtsLimit);
    console.log('apiInsights: ', apiInsights)
    // 3. Check how many modified insights exist in the first 20 API insights
    let existingModifiedCount = apiInsights.filter(apiInsight => 
      filteredModifiedInsights.some(modifiedInsight => modifiedInsight.insightId === apiInsight.insightId)
    ).length;
    console.log('existingModifiedCountInsights: ',existingModifiedCount)

    // 4. Load more insights from API based on how many modified insights exist
    /*
    if (existingModifiedCount < filteredModifiedInsights.length) {
      const additionalCount = filteredModifiedInsights.length - existingModifiedCount;
      const additionalAPIInsights = await fetchAPIInsights(ownerInsigtsLimit, additionalCount);
      console.log('additionalAPIInsights: ', additionalAPIInsights)
      // Merge additional insights with the initial API insights
      apiInsights = apiInsights.concat(additionalAPIInsights);
    }*/

    const additionalCount = filteredModifiedInsights.length || 0;
    const additionalAPIInsights = await fetchAPIInsights(0, ownerInsigtsLimit + additionalCount);
      
    console.log('additionalAPIInsights: ', additionalAPIInsights)

    // Merge modified insights with the API insights and sort them
    const mergedOwnerInsights:ICardInsightsItemForOwner[] | any = this.mergeInsightsData(filteredModifiedInsights, additionalAPIInsights)
      //.sort((a: any, b: any) => a.rankInsightForOwner - b.rankInsightForOwner);
      .sort((a: ICardInsightsItemForOwner | any, b: ICardInsightsItemForOwner | any) => b.sourceTextCreatedMillis - a.sourceTextCreatedMillis);

    this._dataSingleOwnerInsightsSubject.next({data: mergedOwnerInsights, status: 'success'});
    return Promise.resolve(mergedOwnerInsights);

  } catch (error) {
    this._dataSingleOwnerInsightsSubject.next({data:[], status: 'failed'});
    return Promise.reject(error);
  }
}
/*
  public async getSingleOwner1on1Data(showDataForMember: IOwnerLisItem, currOrgCode: string): Promise<IRespInsights1on1ItemForOwner | any>{
    this._dataSingleOwner1on1Subject.next({data:[], status: 'cleared'});
    return new Promise((resolve, reject) => {
      this.getAPIData1on1Insights(showDataForMember?.dealOwnerEmail, currOrgCode)
          .pipe(map((data: ICardInsights1on1Item[]) => data?.filter(data => data?.dealOwnerEmail == showDataForMember?.dealOwnerEmail)))
          .pipe( map((data: ICardInsights1on1Item[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
          .subscribe(
        async (generated1on1Insights: any[]) => {
          const modified1on1Insights:any = await this.actData.getModified1on1Insights(showDataForMember?.uid, currOrgCode);
          const merged1on1Insights:any = this.mergeInsightsData(this.applyPipelinesFilter(this.showDataForPipeline,modified1on1Insights), generated1on1Insights)
            .sort((a:any,b:any) => a.rankInsightForOwner - b.rankInsightForOwner )

          resolve(merged1on1Insights);
          this._dataSingleOwner1on1Subject.next({data:merged1on1Insights, status: 'success'});
        },
        (error:any) => {
          this._dataSingleOwner1on1Subject.next({data:[], status: 'failed'});
          reject(error); // Reject if there is an error in fetching data
        }
      );
    });
  }*/

  public async getSingleOwner1on1Data(showDataForMember: IOwnerLisItem, currOrgCode: string, todolimit: number = 9999): Promise<IRespInsights1on1ItemForOwner | any> {
    this._dataSingleOwner1on1Subject.next({data: [], status: 'cleared'});
    //const ownerInsigtsLimit = 6;
  
    try {
      // 1. Get modified insights
      const modifiedOwnerInsights: any = await this.actData.getModifiedInsightsByType(showDataForMember?.uid, null, currOrgCode, 'oneonone', 'owner'); //this.actData.getModifiedOwner1on1Insights(showDataForMember?.uid, currOrgCode);
      const filteredModifiedInsights: ICardInsightsItem[] = this.applyPipelinesFilter(this.showDataForPipeline, modifiedOwnerInsights);
      const memberEmail = (currOrgCode.includes('demo')) ? 'email@demo' : showDataForMember?.dealOwnerEmail;
      // 2. Fetch the first 20 API insights
      const fetchAPIInsights = (start: number, count: number) => {
        console.log('Owner 1on1 insights', memberEmail, currOrgCode)
        return this.getAPIDataOwner1on1Insights(memberEmail, currOrgCode, start, count)
          .pipe(map((data: ICardInsightsItem[]) => data.filter(data => data.dealOwnerEmail == memberEmail)))
          .pipe(map((data: ICardInsightsItem[]) => this.applyPipelinesFilter(this.showDataForPipeline, data)))
          .pipe(map((data: ICardInsightsItem[] | any) => data.filter((data:any) => data.assocDetailType !='ACT_ALL' ))) // to do: remove in BQ)
          .toPromise();
      };
  
      let apiInsights: ICardInsightsItem[] = await fetchAPIInsights(0, todolimit);
  
      // 3. Check how many modified insights exist in the first 20 API insights
      let existingModifiedCount = apiInsights.filter(apiInsight => 
        filteredModifiedInsights.some(modifiedInsight => modifiedInsight.insightId === apiInsight.insightId)
      ).length;
  
      // 4. Load more insights from API based on how many modified insights exist
      /*if (existingModifiedCount < filteredModifiedInsights.length) {
        const additionalCount = filteredModifiedInsights.length - existingModifiedCount;
        const additionalAPIInsights = await fetchAPIInsights(ownerInsigtsLimit, additionalCount);
  
        // Merge additional insights with the initial API insights
        apiInsights = apiInsights.concat(additionalAPIInsights);
      }*/
      const additionalCount = filteredModifiedInsights.length || 0;
      const additionalAPIInsights = await fetchAPIInsights(0, todolimit + additionalCount);
      
      //console.log('additionalAPI1on1Insights: ', additionalAPIInsights)
  
      // Merge modified insights with the API insights and sort them
      const mergedOwnerInsights:ICardInsightsItem[] | any = this.mergeInsightsData(filteredModifiedInsights, additionalAPIInsights)
        .sort((a: any, b: any) => a.rankInsightForOwner - b.rankInsightForOwner);
  
      this._dataSingleOwner1on1Subject.next({data: mergedOwnerInsights, status: 'success'});
      return Promise.resolve(mergedOwnerInsights);
  
    } catch (error) {
      this._dataSingleOwner1on1Subject.next({data:[], status: 'failed'});
      return Promise.reject(error);
    }
  }

  public async getSingleDeal1on1InsightsData(dealid: string): Promise<IRespInsights1on1ItemForDeal | any> {
    this._dataSingleDeal1on1InsightsSubject.next({data:[], status: 'cleared'});
    const limitGenDealInsights = 15;
    try {
      // Fetch modified deal insights
      const modified1on1DealInsights:any = await this.actData.getModifiedInsightsByType(null, dealid, this.currOrgCode, 'oneonone', 'deal'); //this.actData.getModifiedOrg1on1DealInsights(dealid);
      //console.log('modifiedDealInsights', modifiedDealInsights);
      if (modified1on1DealInsights && modified1on1DealInsights.length > 0) {
        let merged1on1DealInsights: ICardInsightsItem[]|any = [];
        
        await this.getAPIDataSingleDeal1on1Insights(dealid, this.currOrgCode)
          .pipe(map((data: ICardInsightsItem[]| any) => {
            if(this.currOrgCode.includes('demo')){
              return data.filter((data:ICardInsightsItem[]| any) => data?.dealId == dealid)
            } else return data.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
          }))
          .pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
          .toPromise()
          .then(
            (generatedDealInsights) => {
              //console.log('generatedDealInsights',generatedDealInsights);
              merged1on1DealInsights= this.mergeInsightsData(modified1on1DealInsights, generatedDealInsights)
              .sort((a:ICardInsightsItem,b:ICardInsightsItem) => b.insightCreated - a.insightCreated )
            }
          );
        
        //console.log('mergedDealInsights',mergedDealInsights);
        //take modified data and add not existing ones from generated
        
        // Update the subject with the retrieved data
        this._dataSingleDeal1on1InsightsSubject.next({data: merged1on1DealInsights, status: 'success'});
        // Resolve the promise with the retrieved data
        return merged1on1DealInsights;
      } else {
        // If there are no modified insights, just go for generated
        const generated1on1DealInsights = await this.getAPIDataSingleDeal1on1Insights(dealid, this.currOrgCode)
        .pipe(map((data: ICardInsightsItem[]| any) => {
          if(this.currOrgCode.includes('demo')){
            return data.filter((data:ICardInsightsItem[]| any) =>  data?.dealId == dealid)
          } else return data.filter((data:any) => data.assocDetailType !='ACT_ALL' ) // to do: remove in BQ
        }))
        .pipe(map((data: ICardInsightsItem[]|any) => data.sort((a:any,b:any) => a.insightCreated - b.insightCreated )))
        .pipe(map((data: ICardInsightsItem[]|any) => data.slice(0,limitGenDealInsights) ))
        .toPromise()
        this._dataSingleDeal1on1InsightsSubject.next({data: generated1on1DealInsights, status: 'success'});
        return generated1on1DealInsights;
      }
    } catch (error) {
      this._dataSingleDeal1on1InsightsSubject.next({data:[], status: 'failed'});
      return Promise.reject(error);
    }
  }

  public getOrgFieldDistinctData(field: string, initialFilter: string[] = []): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.getAPIDataOrgFieldDistinct(field, this.currOrgCode).pipe(
        // turns {pipeline: 'Pipeline 1'}[] into ['Pipeline 1']
        map((data: { [key: string]: string }[]) => data.map(item => item[field]))
        //map((data: any) => data)
      )
      .pipe(map((data: string[]) => {
        if (initialFilter.length > 0) {
          return data.filter(item => initialFilter.includes(item))
        } else return data;
      }
      ))
      .subscribe(
        (data: string[]) => {
          //console.log('single field distinct data:',field, data);
          resolve(data);
          this._dataOrgFieldDistinctSubject.next(data);
        },
        (error:any) => {
          this._dataOrgFieldDistinctSubject.next([]);
          reject(error); // Reject if there is an error in fetching data
        }
      );
    });
  }

  private mergeInsightsData(modifiedInsights: ICardInsightsItem[], generatedDealInsights: ICardInsightsItem[]): ICardInsightsItem[] {

    //const hasValue = (obj:ICardInsightsItem, key: 'insightId', value:any) => obj[key] === value;
     let mergedInsights: ICardInsightsItem[] = [];
    
    generatedDealInsights?.forEach((genInsight: ICardInsightsItem) => {
      const matchingInsight = modifiedInsights?.find(modInsight => modInsight?.insightId == genInsight?.insightId);
     if (!matchingInsight) {
         mergedInsights.push(genInsight);
     } 
      
    })
    mergedInsights.concat(modifiedInsights);
    return  mergedInsights.concat(modifiedInsights);
  }

  public moveInsightToNewState(insight: ICardInsightsItem, state: IInsightState, insightCategory: TInsightCategory) {

    insight.insightState = state;
    //console.log('update insight to done:', insight);
    this.actData.updateInsight(insight, insightCategory);
    //const val = this._dataSingleDealInsightsSubject.getValue()
    this._dataDealBucketsSubject.next({data:[], status: 'cleared'});
   }

  /************************* DATA MODIFICATION BEFORE LOADING TO UI ***************************/

  private sumOwnerLevelDataByGlobalFilter(ownerRawData: IOwnerDataByOwnerByPipeline[]): IOwnerDataByOwnerByPipeline {
    let pipelineSummary!: IOwnerDataByOwnerByPipelineSum;
    const pipelineSummaryInit: IOwnerDataByOwnerByPipelineSum = {
      dealOwnerId: null,
      pipeline: '', 
      dealOwnerEmail: '',
      ownerCurrAllCnt: 0,
      ownerCurrOpenCnt: 0,
      ownerHistWonCnt: 0, //won in number of deals in previous 4 quarters (exluding current)
      ownerHistAllCnt: 0, //all owner deals in number of deals in previous 4 quarters (exluding current)
      ownerHistWinRateCnt: 0, // = ownerHistWon/ownerHistAll
      ownerCurrWonAmt: 0, // aka sales generated; Amount (Amount in company currency) for all won deals by owner THIS quarter
      ownerCurrOpenAmt: 0,
      dealsAtRisk: 0, //number of Deals with win probability below 50% (later to discuss: or num of deals from buckets where dealsAtRisk rank is not null)
      avgActivityScore: 0, //avg score by owner by pipeline rounded (no decimals); when score not calculated show null
      avgEngagementScore: 0, //avg score by owner by pipeline rounded (no decimals); when score not calculated show null
      avgWinrateScore: 0,
      cnt_avgActivityScore: 0,
      cnt_avgEngagementScore: 0,
      cnt_avgWinrateScore: 0
    }

    pipelineSummary = pipelineSummaryInit;
    
      let pipelinesNum = ownerRawData.length;
      if (pipelinesNum > 0) {
      //console.log('owner raw data', ownerRawData.pipelineData,pipelinesNum,ownerRawData.pipelineData);
  
      ownerRawData
      .forEach((p:IOwnerDataByOwnerByPipeline) => {
        pipelineSummary.pipeline = '',
        pipelineSummary.dealOwnerId = 0,
        pipelineSummary.dealOwnerEmail = p.dealOwnerEmail,
        pipelineSummary.ownerCurrAllCnt += (p.ownerCurrAllCnt) ? p.ownerCurrAllCnt : 0,
        pipelineSummary.ownerCurrOpenCnt += (p.ownerCurrOpenCnt) ? p.ownerCurrOpenCnt : 0,
        pipelineSummary.ownerHistWonCnt += (p.ownerHistWonCnt) ? p.ownerHistWonCnt : 0,
        pipelineSummary.ownerHistAllCnt += (p.ownerHistAllCnt) ? p.ownerHistAllCnt : 0,
        pipelineSummary.ownerHistWinRateCnt = 0;
        pipelineSummary.ownerCurrWonAmt += (p.ownerCurrWonAmt) ? p.ownerCurrWonAmt : 0,
        pipelineSummary.ownerCurrOpenAmt += (p.ownerCurrOpenAmt) ? p.ownerCurrOpenAmt : 0,
        pipelineSummary.dealsAtRisk += (p.dealsAtRisk) ? p.dealsAtRisk : 0;
        pipelineSummary.avgActivityScore += (p.avgActivityScore) ? p.avgActivityScore :0,
        pipelineSummary.avgEngagementScore += (p.avgEngagementScore) ? p.avgEngagementScore : 0,
        pipelineSummary.avgWinrateScore += (p.avgWinrateScore) ? p.avgWinrateScore : 0,
        pipelineSummary.cnt_avgActivityScore += (p.avgActivityScore) ? 1 : 0,
        pipelineSummary.cnt_avgEngagementScore += (p.avgEngagementScore) ? 1 : 0,
        pipelineSummary.cnt_avgWinrateScore += (p.avgWinrateScore) ? 1 : 0
      });

      pipelineSummary.avgActivityScore = Math.round( pipelineSummary.avgActivityScore /pipelineSummary.cnt_avgActivityScore);
      pipelineSummary.avgEngagementScore = Math.round ( pipelineSummary.avgEngagementScore  / pipelineSummary.cnt_avgEngagementScore);
      pipelineSummary.avgWinrateScore = Math.round (pipelineSummary.avgWinrateScore  /pipelineSummary.cnt_avgWinrateScore);
      pipelineSummary.ownerHistWinRateCnt = (pipelineSummary.ownerHistAllCnt && pipelineSummary.ownerHistAllCnt !=0) ? Math.round (pipelineSummary.ownerHistWonCnt/pipelineSummary.ownerHistAllCnt*100)/100 : 0;
      //pipelineSummary.salesCoverageLabel = ' Sales Coverage';
      //pipelineSummary.salesGeneratedLabel = ' Sales Generated';
      pipelineSummary.dealOwnerId = ownerRawData[0].dealOwnerId;
      pipelineSummary.dealOwnerEmail = ownerRawData[0].dealOwnerEmail;
      
      //(pipelineSummary.salesCoverageSum !=0 ) ?
      //  pipelineSummary.progressPercCalc = (pipelineSummary.salesGeneratedSum / pipelineSummary.salesCoverageSum)* 100 : 0;
  
      //console.log('pipelinesum: ', pipelineSummary);
      //pipelineSummary = pipelineSummaryInit;
    }

    return pipelineSummary;
    
  }

  public addIcon(engDetailType: TDealEngScoreDetailsType): string {
    switch(engDetailType){
      case 'ENG_ASK': return 'cil-speech';
      case 'ENG_DECMAKER': return 'cil-briefcase';//cil-wallet
      case 'ENG_ENGSEQUENCE': return 'cil-media-skip-forward';//cil-clear-all
      case 'ENG_LOSEREASON': return 'cil-frown';//cil-thumb-down, cil-warning
      case 'ENG_SENTIMENT': return 'cil-heart';//cil-smile-plus
      case 'ENG_WINREASON': return 'cil-badge';
      default: return ''
    }
  }

  public replaceWithLabel(engDetailType: TDealEngScoreDetailsType):TCardEngDetailsTypeLabel | any {
    switch(engDetailType){
      case 'ENG_ASK': return 'Asks';
      case 'ENG_DECMAKER': return 'Decision Maker';
      case 'ENG_ENGSEQUENCE': return 'Engagement Sequence';
      case 'ENG_LOSEREASON': return 'Losing Signals';
      case 'ENG_SENTIMENT': return 'Sentiment';
      case 'ENG_WINREASON': return 'Positive Signals';
      default: return null
    }
  }

  public parseEngScoreDetails(inputData: IDealEngScoreSummaryItem[]): ICardEngDetailsItem | any {

    let outputData: ICardEngDetailsItem[] = [];
    inputData.forEach((inputData: IDealEngScoreSummaryItem) => {
      outputData.push({
        cardType: inputData.scoreType,
        cardTypeLabel: this.replaceWithLabel(inputData.scoreType),
        cardValue: inputData.scoreAvgValue,
        iconName: this.addIcon(inputData.scoreType)
      })
    })
    //console.log('Parse----', inputData, 'into', outputData );
    return outputData;
  }

  public async removeBlackListed(data: any[], dataKeyId: string): Promise<any[]> {
    try {
      // Fetch blacklisted items asynchronously
      const blackListedItems = [] = await this.getBlackListedItems();
  
      // Filter out blacklisted items
      const cleanedData: any[] = [] = (blackListedItems && data) ? data?.filter(item => !blackListedItems?.some((blackListedItem: any) => blackListedItem?.itemId === item[dataKeyId])) : [];
  
      console.log('Remove black listed', blackListedItems, 'from', cleanedData);
      return cleanedData;
    } catch (error) {
      console.error('Failed to remove blacklisted items', error);
      return data; // Return original data in case of an error
    }
  }
  
  private getAPIBlackListed(): Observable<any> {
    const currOrg = this.currOrgCode;
    return this.storageSrc.loadJsonFile(`${currOrg}/_config/${currOrg}_blackListed.json`);
  }
  
  public async getBlackListedItems(): Promise<any[]> {
    try {
      // Use lastValueFrom to convert Observable to Promise
      const blackListedItems = await lastValueFrom(this.getAPIBlackListed().pipe(map((data: any[]) => data)));
      return blackListedItems;
    } catch (error) {
      //console.error('Failed to fetch blacklisted items', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }
}
