import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment.dev';
import { User } from './user';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';

export type GAEventCategory = 'Navigation' | 'App Access' | 'Feature Usage' | 'User Management';
export type GAEventAction = 'App Load' | 'App Logging' | 'App Logout' | 'App SignUp' | 'Integrate' | 'Delete Integration' | 'Invite' | 'Un-Invite' | 'Page Opening';
export type TPageNames = 'AIMARS' | 'My Overview' | 'My Deals' | 'M1on1 - My Overview' | 'M1on1 - Org Overview' | 'Team Settings' | 'Deal Details';
export type GAEventLabelName = TPageNames | 
  'SignUp with User & Password'| 'LogIn with Google' | 'LogIn with User & Password' | 'LogOut' |
  'Integrate for Org' | 'Delete Integration for Org' |
  'Invite Member' | 'Un-Invite Member'
  ;
export type GAEventLabelValue = string | number;
export type TGAClientType = 'non-client' | 'client';

export interface GAEvent {
  _eventCategory: GAEventCategory,
  _eventAction: GAEventAction,
  _eventLabelName: GAEventLabelName,
  _eventLabelValue?: GAEventLabelValue,
  _eventDataId?: number | string//,
  _eventUserId?: string;//google user id
  _eventOrgId?: string;//ORG001
  _eventClientType?: TGAClientType;
}

export interface GAEventParams {
  event_category: GAEventCategory,
  event_action: GAEventAction,
  event_label_name: GAEventLabelName,
  event_label_value: GAEventLabelValue,
  event_data_id: number | string,
  event_user_id: string,
  event_org_id: string
}

export interface GAEventSavedFirebase extends GAEventParams {
  userEmail: string,
  timestamp: any,
  timestampMillis: number,
  isTimestampMillis: true, //needed for later where clause by timestampMillis where it exists
  clientType: TGAClientType
}

export interface ISavedAnalyticEvent {
  data: GAEventSavedFirebase,
  id: string,
  path: string
}

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  
  private loggedUser!: User;
  private userOrg: any = null;

  constructor(public analytics: AngularFireAnalytics,
              private afs: AngularFirestore,
              private http: HttpClient,
    ) { 
      this.loggedUser = JSON.parse(localStorage.getItem('user')!);   
      this.userOrg = JSON.parse(localStorage.getItem('orgCodeCurr')!);

  }

  public _dataOrgUserAnalytics = new Subject<any>(); /*IRespIOrgUserAnalytics*/
  private postAPIGetOrgUsersAnanalytics(/*memberEmail: string,*/ orgCode: string): Observable<any> {
    //if (orgCode == null) return of(null).toPromise();
    return this.http.post<void>(environment.firebase.functionsUrl+'/getOrgUsersAnanalytics',{/*email: memberEmail,*/orgcode: orgCode});
  }

  public getOrgUsersAnanalytics(/*memberEmail: string,*/ orgCode: string){
    console.log('USAGE ANALYTICS | run user analytics for org');
    this._dataOrgUserAnalytics.next({data:[], status:'cleared'});
    return new Promise((resolve, reject) => {
      //const userId = this.loggedUser.email;
      //const orgId = await this.getUserOrg();
      this.postAPIGetOrgUsersAnanalytics(/*memberEmail,*/ orgCode)
        .pipe(
          map((data: GAEvent[]) => data)
        )
        .subscribe(
          async (data: GAEvent[]) => {
  
            //const cleanedData = await this.removeBlackListed(filteredData, 'dealId');
            resolve(data);
            this._dataOrgUserAnalytics.next({data: data, status:'success'});
          },
          error => {
            reject(error); // Reject if there is an error in fetching data
            this._dataOrgUserAnalytics.next({data:[], status:'failed'});
          }
        );
    });
  }

  TestAnalytics() {
    return this.analytics.logEvent('test-clicks', {
      eventCategory: 'Videos',
      eventAction: 'play',
      eventLabel: 'Fall Campaign'
    }
    ).finally(
      () => console.log('GA Event sent')
    );
  }

  public async LogClickEvent(
    //_eventName: string,
    _gaEvent: GAEvent,
    orgId: any = null

  ):Promise<void> {
    let loggedUserId = this.loggedUser.uid;//await this.getUserId();//
    let userOrg = this.userOrg || await JSON.parse(localStorage.getItem('orgCodeCurr')!) || await this.getUserDefaultOrgCode(this.loggedUser); //this.userOrg;//await this.getUserOrg();//this.currOrgId;//
    const clientType: 'non-client' | 'client' = environment.dataConfig.nonClientEmailMatch.some(substring => this.loggedUser?.email?.includes(substring)) ? 'non-client' : 'client';
    let _eventName = `${_gaEvent._eventAction} -  ${_gaEvent._eventLabelName}`;
    try {
        console.log('GA | Logging event to analytics:', {
        _eventName,
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue,
        event_data_id: _gaEvent._eventDataId,
        event_user_id: loggedUserId,
        event_org_id: orgId || userOrg,
        event_client_type: clientType
      });
  
      await this.analytics.logEvent(_eventName, {
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue,
        event_data_id: _gaEvent._eventDataId,
        event_user_id: loggedUserId,
        event_org_id: orgId || userOrg,
        event_client_type: clientType
      });
  
      console.log('GA | Event sent:', {
        _eventName,
        ..._gaEvent,
        event_user_id: loggedUserId,
        event_org_id: orgId || userOrg,
        event_client_type: clientType
      });
      
      await this.saveEventInFirebase({
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue || '',
        event_data_id: _gaEvent._eventDataId || '',
        event_user_id: loggedUserId,
        event_org_id: orgId || userOrg
      }, this.loggedUser.email);

      this.trackEvent(_eventName, {
        event_category: _gaEvent._eventCategory,
        event_action: _gaEvent._eventAction,
        event_label_name: _gaEvent._eventLabelName,
        event_label_value: _gaEvent._eventLabelValue,
        event_data_id: _gaEvent._eventDataId,
        event_user_id: loggedUserId,
        event_org_id: orgId || userOrg,
        event_client_type: clientType
      })

      return Promise.resolve();
  
    } catch (error) {
      console.error("GA Error", error);
      return Promise.reject(error); // Reject the promise explicitly
    }
  }

  async getUserId(){
    this.loggedUser = await JSON.parse(localStorage.getItem('user')!);
    console.log('Logged user: ', this.loggedUser.uid);
    if (this.loggedUser.uid == undefined) {
      return 'unknown user'
    } else {
      return this.loggedUser.uid;
    }
  }

  async getUserOrg(){
    return this.userOrg = await JSON.parse(localStorage.getItem('orgCodeCurr')!);
  }

/* Turn off Aplitude: */
/*
  public initializeAmplitude() {
    if ((window as any).amplitude) {
      // Initialize Amplitude with the environment-specific API key
      (window as any).amplitude.init(environment.connectedTools.amplitudeApiKey, {
        serverZone: 'EU',
        autocapture: { elementInteractions: true },
      });

      if (this.loggedUserId) {
        (window as any).amplitude.setUserId(this.loggedUserId);
        console.warn('AMPLITUDE | Tracking user: ', this.loggedUserId);
      } else {
        console.warn('AMPLITUDE | User ID is not available to set in Amplitude');
      }

      if (this.currOrgId) {
        (window as any).amplitude.setUserProperties({
        orgcode: this.currOrgId
        })
        console.warn('AMPLITUDE | Tracking oganization: ', this.currOrgId);
      } else {
          console.warn('AMPLITUDE | ORG ID is not available to set in Amplitude');
      } 

    } else {
      console.error('Amplitude is not loaded!');
    }
  }*/

    public initializeAmplitude(userId: string, currOrgId: string) {
      if ((window as any).amplitude) {
        // Initialize Amplitude with the environment-specific API key
        (window as any).amplitude.init(environment.connectedTools.amplitudeApiKey, {
          serverZone: 'EU',
          autocapture: { elementInteractions: true },
          batchEvents: false
        });
    
        // Ensure Amplitude instance is ready
        //const amplitudeInstance = (window as any).amplitude.getInstance();
    
        if ((window as any).amplitude) {
          // Set the user ID
          if (this.loggedUser) {
            (window as any).amplitude.setUserId(this.loggedUser.uid);
            console.warn('AMPLITUDE | Tracking user -> : ', this.loggedUser.uid);
          }
    
          // Set user properties if provided
          this.setTrackingCurrOrg(currOrgId);

        } else {
          console.error('Amplitude instance is not ready.');
        }
      } else {
        console.error('Amplitude is not loaded!');
      }
    }

  public setTrackingCurrOrg(currOrgId: string) {
    const clientType: 'non-client' | 'client' = environment.dataConfig.nonClientEmailMatch.some(substring => this.loggedUser?.email?.includes(substring)) ? 'non-client' : 'client';
  
    if (currOrgId) {
      const identifyObj = new (window as any).amplitude.Identify();  
      
      identifyObj.set('user_orgcode', currOrgId); 
      identifyObj.set('user_type', clientType);  

      (window as any).amplitude.identify(identifyObj);
      console.warn('AMPLITUDE | Tracking organization:', currOrgId);
    } else {
        console.warn('No user properties provided.');
    }
  }

  public trackEvent(eventName: string, eventProperties?: object) {
    if ((window as any).amplitude) {
      (window as any).amplitude.logEvent(eventName, eventProperties);
    } else {
      console.error('AMPLITUDE | Amplitude is not loaded!');
    }
  }



  private saveEventInFirebase(event: GAEventParams, email: string) {
    const onlySavedEvents: GAEventCategory[] = ['Navigation', 'App Access'];

    if ( onlySavedEvents.includes(event.event_category) ) {
      const timestamp = new Date().getTime();
      const timestampString = new Date();
      console.log('timestamp', timestamp, timestampString);
      //const year = timestampString.getFullYear();
      //const month = String(timestampString.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
      //const yyyymm = `${year}${month}`;

      const orgId = (event.event_org_id) ? event.event_org_id : '_unknown_';
      const userId = (event.event_user_id) ? event.event_user_id : '_unknown_';
      const clientType: 'non-client' | 'client' = environment.dataConfig.nonClientEmailMatch.some(substring => this.loggedUser?.email?.includes(substring)) ? 'non-client' : 'client';

      const saveEvent: GAEventSavedFirebase = {
          ...event,
          userEmail: email,
          timestamp: timestampString,
          timestampMillis: timestamp,
          isTimestampMillis: true, //needed for later where clause by timestampMillis where it exists
          clientType: clientType
        }
      
      return this.afs.collection(`analytics/${orgId}/${userId}`).add(saveEvent);

    } else return null;
    
  }


  public getClientType(email:string): TGAClientType {
    return environment.dataConfig.nonClientEmailMatch.some(substring => email.includes(substring)) ? 'non-client' : 'client';

  }

  public isClientUser(email:string):boolean {
    const clientType:TGAClientType = this.getClientType(email);
    return (clientType == 'client') ? true : false;
  }



  async getUserDefaultOrgCode(user: User): Promise<string | []> {
    try {
      const userRef: AngularFirestoreDocument<User> = this.afs.collection('users').doc(user.uid);
      const doc = await userRef.get().toPromise();

      if (doc?.exists) {
        const orgCodes = doc.data()?.orgCodes ? doc.data()?.orgCodes : [];
        
        let orgCodeCurrent =  JSON.parse(localStorage.getItem('orgCodeCurr')!);
        
        if (!orgCodeCurrent) {
          orgCodeCurrent = orgCodes ? this.getOrgCodeNotDemo(orgCodes) : null;
          console.log('GA | USAGE | No current org, change to default from database:', orgCodeCurrent);
        }

        return orgCodeCurrent;
      } else {

          console.log('USAGE | User does not exist');

        return [];
      }
    } catch (error) {
      console.error('USAGE | Error getting user document:', error);
      throw error; // Re-throw the error to handle it outside
    }
  }

  public getOrgCodeNotDemo(orgCodes: string[]): string | null {
    const nonDemoCodes = orgCodes.filter(code => !code.includes('demo'));
    
    if (nonDemoCodes.length > 0) {
      console.log(')))))))) cur org:',nonDemoCodes[0])
      return nonDemoCodes[0];
    } else if (orgCodes.includes('demo')) {
      console.log(')))))))) cur org:','demo - selected')
      return orgCodes[0];
    } else {
      console.log(')))))))) cur org:','null - selected')
      return orgCodes[0];
    }
  }
}
